.modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    transition: all 500ms ease;
    opacity: 0;
    visibility: hidden;
    z-index: 100;

    &.active {
        opacity: 1;
        visibility: visible;
        transition: all 800ms ease;
    }

    &--container {
        display: flex;
        border-radius: 8px;
        height: auto;
        width: 900px;
        margin: auto;
        background: #fff;
        box-shadow: 1px 7px 25px rgba(0, 0, 0, .6);
        transition: all 800ms ease;

        &--container {
            width: 55%;
            padding: 1.5rem;
        }

        &--image {
            position: relative;
            height: 100%;
            width: 100%;
            border-radius: 8px;

            &>img {
                height: 100%;
                border-radius: 8px;
                width: 100%;
                object-fit: cover;
                object-position: 23%;
            }
        }

        &--form {
            margin: 2rem 0px;
            padding: 0px 1.5rem;

            &>h1 {
                font-family: 'Work Sans';

                font-weight: 600;
                font-size: var(--fs-3);

                color: #454F5B;
            }

            & > form {
                margin: 1.5rem 0px;

                &>label, & > div > div > label {
                    font-family: 'Work Sans' !important;
                    font-style: normal !important;
                    font-weight: 600 !important;
                    font-size: 12px !important;

                    color: #454F5B !important;
                }

                &>input, & > div > div > input {
                    width: 100% !important;
                    height: 48px !important;
                    padding: 1rem !important;
                    background: #FFF !important;
                    border: 1px solid #D7E1EA !important;

                    &::placeholder {
                        color: #454F5B !important;
                    }
                }
            }

            &>label,
            input {
                display: block !important;
                margin: 0.5rem 0px !important;
            }
        }
    }
}

.modalQuery {
    width: 100%;
    border-radius: 4px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    transition: all 500ms ease;
    opacity: 0;
    visibility: hidden;
    z-index: 100;

    &.active {
        opacity: 1;
        visibility: visible;
        transition: all 800ms ease;
    }

    &--container {
        display: flex;
        border-radius: 8px;
        height: auto;
        width: 900px;
        margin: auto;
        background: #fff;
        box-shadow: 1px 7px 25px rgba(0, 0, 0, .6);
        transition: all 800ms ease;

        &--container {
            width: 100%;
            padding: 1.5rem;
        }
    }
}

.gradient {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 23.56%, rgba(0, 0, 0, 0) 63.5%);
    z-index: 2;
}

.contact_form_modal{

}