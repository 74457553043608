.breadcrumbs {
  &__list {
    display: flex;
    padding: 0;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }

  &__item {
    color: #FFFFFF;
    font-family: var(--ff-secondary);

    a {
      font-family: var(--ff-secondary);
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF !important;

      @include hover {
        color: var(--c-secondary) !important;
      }

      @include m_query($boo-sm) {
        font-size: 14px;
      }
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.125 5.30333L0 1.17833L1.17833 0L6.48167 5.30333L1.17833 10.6067L0 9.42833L4.125 5.30333Z' fill='white'/%3E%3C/svg%3E%0A");
      margin: 0 1rem;

      @include m_query($boo-sm) {
        margin: 0 0.6rem;
        transform: scale(0.8) translateY(2px);
        position: relative;
        display: inline-block;
      }
    }

    &:last-child {

      &::after {
        content: '';
      }
    }
  }

  &--only-link{
    display: none;
  }

  &--sticky-container {
    position: fixed;
    top: 5.4rem;
    width: 100%;
    background-color: #2f5d83;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    transition: .3s opacity, .3s visibility;


    @include m_query(1100px) {
      top: 4.6rem;
    }

    @include m_query($boo-sm) {
        display: none;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }

    .breadcrumbs__list {
      --p: 1rem;
      margin: 0 auto;
      padding: 0.5rem var(--p);

      @include m_query(640px, 'min') {
        --p: 2rem;
      }

      @include m_query(1024px, 'min') {
        --p: 4rem;
      }

      @include m_query(1280px, 'min') {
        --p: 5rem;
      }

      @include m_query(1432px, 'min') {
        --p: 6rem;
      }
    }

    li {
      // color: #637381;
      font-weight: 500;
      position: relative;

      &::after {
        // filter: invert(1);
      }
    }

    a {
      // color: #637381;
    }
  }

}