.row-products-services{
    .col{
        @include m_query($boo-lg) {
            @include col(6);
        }

        @include m_query($boo-sm) {
            @include col(12);
        }
    }
}