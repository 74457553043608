.pagination {

  &_wrapper {
    margin: var(--sp-80) 0;
  }

  &_list {
    align-items: center;
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin: 0;
    padding: 0;
  }

  &_item {
    font-family: var(--ff-primary);

    font-size: 16px;

    color: #454F5B;


    &.previous, &.next {
      background-color: var(--c-secondary);
      backdrop-filter: blur(80px);
      border-radius: 4px;
    }

    &_link {
      color: #454F5B;


      &:hover {
        color: var(--c-secondary);
        font-weight: 900;
      }
    }

    &_icon {
      // padding: 1rem 1.25rem;
      width: var(--size);
      height: var(--size);
      --size: 40px;
      object-fit: contain;
      padding: 0.8rem;
    }

    &.active {
      color: var(--c-secondary);
      font-weight: 900;
    }
  }
}

.document_list{
  + .pagination_wrapper{
    margin-bottom: 0!important;
  }
}