.footer {
    background: var(--c-primary);
    color: white;
    overflow: hidden;

    .col-menus {
        @include col(8);

        @include m_query($boo-md) {
            @include col(7);
        }

        @include m_query($boo-sm) {
            @include col(12);
            margin-top: 2rem;

            .footer__menu-content {
                display: none;
            }

            .footer__menu-title {
                display: flex;
                justify-content: space-between;
                position: relative;
            }
        }

        .row>* {
            @include col(3);

            @include m_query($boo-lg) {
                @include col(6);
            }

            @include m_query($boo-sm) {
                @include col(12);
            }
        }
    }

    &__btn-open {
        margin-left: 1rem;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &[aria-expanded="true"] {
            .icon {
                transform: rotate(180deg);
            }
        }

        .icon-arrow {
            filter: invert(1);
            transition: .6s;
        }

        @include m_query($boo-sm, 'min') {
            display: none;
        }
    }


    &__menu-wrapper {
        & > a {
            font-size: var(--fs-2) !important;
            margin-bottom: 0.5rem !important;
            color: #FFF !important;
            font-weight: 600 !important;
            font-family: 'Work Sans';
        }
        @include m_query($boo-sm) {
            text-align: left;
        }
    }

    &__menu-title {
        font-size: var(--fs-2);
        margin-bottom: 0.5rem;
    }

    &__menu-content {

        ul {
            @include list-unstyled;
        }

        li+li {
            margin-top: 0.4rem;
        }

        &--links {
            font-size: 14px;
            color: white;
            text-decoration: none;

            @include hover {
                color: var(--c-secondary);
            }
        }
    }

    .info-top {
        padding: 2.5rem 0;
        text-align: center;
        border-bottom: 1px solid rgba(215, 225, 234, 0.21);

        @include m_query($boo-lg) {
            --bs-gutter-y: 1.5rem;
        }

        @include m_query($boo-sm) {
            --bs-gutter-y: 0;

            >*+* {
                margin-top: 1rem;
            }
        }

        .footer {
            &__menu-content--links{
                font-family: var(--ff-secondary);
                font-size: 14px;
            }
        }
    }

    .info-bottom {
        padding: 2.5rem 0;

        .footer {
            &__menu-wrapper {
                margin-bottom: 2.5rem;

                @include m_query($boo-sm) {
                    margin-bottom: 0.8rem;
                }
            }
        }
    }

    &__info {
        .footer__menu-title {
            margin-bottom: 1rem;
        }
    }

    &__social-icons {
        ul {
            @include list-unstyled;
            display: flex;
        }

        a {
            display: flex;
            transition: .3s opacity;

            @include hover {
                opacity: .5;
            }
        }

        li+li {
            margin-left: 1.5rem;
        }

        .icon {
            --size: 1.5rem;
            filter: invert(1);
        }
    }

    &__logo {
        margin-bottom: 1.5rem;
    }

    &__logo-info p{
        font-family: var(--ff-secondary);
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2.5rem;
        max-width: 220px;
    }

    &__logo-fogade {}

    &__copyright {
        padding: 1rem;

        @include m_query($boo-sm) {
            padding: 0;
        }

        ul {
            @include list-unstyled;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        a {
            color: white;
            text-decoration: none;
            font-size: 14px;
            font-family: var(--ff-secondary);

            @include hover {
                color: var(--c-secondary);
            }

            @include m_query($boo-sm) {
                font-size: 10px;

            }


        }

        li {
            margin-left: 0.8rem;
            margin-right: 0.8rem;
            text-align: center;

            @include m_query($boo-sm) {
                @include col(6);
                margin: 0;
                text-align: left
            }
        }
    }
}

.footer-bt {
    padding: var(--sp-80) 0;
    border-top: 2px solid #f0f4f8;

    .info-bottom{
        .col-12{
            display: none;
        }
    }
    .info-top{
        justify-content: space-between;
    }

    .sc-ubication{
        // flex: 0 1 400px;

        @include m_query($boo-lg) {
            // flex: 1;
        }
    }


    .sc-home {
        @include m_query(1280px) {
            flex: 1;
        }

        @include m_query($boo-lg) {
            flex: 0 0 auto;
            width: auto;
        }

    }

    &__item {
        max-width: 320px;
        padding: 2rem 0;
        display: block;
        color: var(--c-body-primary);

        @include m_query($boo-sm) {
            padding: 0;
        }

        &--btn {
            background: var(--c-gray-light);
            text-align: left;
            padding: 2rem;
            border-radius: 10px;
            background: #F0F4F8;
            max-width: none;
            width: 100%;
            position: relative;
            transition: var(--g-trans) background, var(--g-trans) box-shadow;

            &::after {
                --size: 20px;
                content: '';
                position: absolute;
                top: 50%;
                right: 2rem;
                width: var(--size);
                height: var(--size);
                transform: translateY(-50%);
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='13' viewBox='0 0 20 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99121 7.72286L2.60199 0.0859376L0.491211 2.26747L9.99121 12.0859L19.4912 2.26748L17.3804 0.0859382L9.99121 7.72286Z' fill='%2321BAB3'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: contain;
                transition: var(--g-trans) transform;
                transform-origin: top;

            }

            @include hover{
                box-shadow: inset 0 0 0px 3px #c9d1d7;
                background: #ebf0f4;
            }

            &.active{
                box-shadow: inset 0 0 0px 3px #c9d1d7;
                background: #dee6ed;

                &::after{
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__icon {
        width: 2.5rem;
        margin-bottom: 0.8rem;
    }

    &__title {
        font-size: var(--fs-2);
    }

    &__content {
        & > p {
            margin: 0px;
        }
    }

    &__details {
        background: #F0F4F8;
        padding: var(--sp-64);
        border-radius: 10px;
        display: flex;
        margin-top: 1rem;

        @include m_query($boo-xl) {
            flex-wrap: wrap;
        }
    }

    &__ubication-content {}

    &__detail-item {
        .footer-bt__title {
            margin-bottom: 1.5rem;
        }
    }

    .central {
        @include col();
        @include m_query($boo-xl) {
            @include col(12);
        }

        @include m_query($boo-xl, 'min') {
        padding-right: 2rem;

        }

        .footer-bt__ubication-item{
            @include m_query($boo-xl) {
                @include col(6);
            }

            @include m_query($boo-sm) {
                @include col(12);
            }
        }
    }

    .departaments {
        padding-left: 1rem;
        @include col(8);

        @include m_query($boo-xl) {
            @include col(12);
            padding-left: 0;
        }

        @include m_query($boo-xl, 'min') {
            border-left: 2px solid #D3DAE1;
        }

        .footer-bt__ubication-item {
            @include col(6);

            &:last-child{
                flex: 0 0 100%;
                max-width: 100%;
            }

            @include m_query($boo-sm) {
                @include col(12);
            }
        }

        .footer-bt__title{
            @include m_query($boo-sm, 'min') {
                text-align: center;
            }
        }
    }

    &__ubications {
        display: flex;
        flex-wrap: wrap;
    }

    &__ubication-item {
        display: flex;
        max-width: 340px;
        margin-bottom: 1.5rem;
        color: var(--c-body-primary);

        @include m_query($boo-lg, 'min') {
            padding-left: 2rem;
        }
    }

    &__ubication-icon {
        margin-bottom: auto;
        margin-right: 1rem;
        width: 28px;
    }

    &__ubication-title {
        font-size: var(--fs-1);
        font-weight: 700;
    }

    &__ubication-address {}
}

.menuIsActive {
    display: block !important;
}