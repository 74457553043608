$breakpoint-m: 1100px;

.top-header {
  align-items: center;
  background: #F0F4F8;
  border-bottom: 1px solid rgba(165, 181, 197, 0.5);
  animation: fade-in 0.7s;

  @include m_query($boo-sm) {
    display: none;
  }


  &_wrapper {
    display: flex;
    column-gap: 2.75rem;
    justify-content: flex-end;
    min-height: 2.5rem;

    @media screen and (min-width: 1280px) {
      max-width: 1464px;
      padding: 0 2rem;
    }

    &>* {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
    }
  }

  &--search {}

  &--menu {
    column-gap: 1.5rem;

    &>* {
      display: flex;
      column-gap: 0.625rem;
      font-family: var(--ff-secondary);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;

      color: #454F5B;

      >a {
        display: flex;
        column-gap: 0.625rem;
        color: #454F5B;
      }
    }

  }

  &--social {
    column-gap: 1.75rem;
  }
}


.main-header {

  &_wrapper {
    display: flex;

    @include m_query(1280px, 'min') {
      max-width: 1464px;
      //padding: 0 2rem;
    }

    // @include m_query($boo-sm, 'min') {
    //   min-height: 7.5rem;
    // }

    .main-header_navbar--item--background {
      @include m_query(1280px) {
        top: 80px;
      }
    }
  }


  &_navbar {
    // align-items: center;
    // column-gap: 3rem;
    // display: flex;
    // justify-content: space-between;
    // width: 100%;

    &--menu {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 0;
      height: 100%;

      &>li {
        height: 100%;
        padding: 2rem 1.5rem;
        align-items: center;
        display: flex;
        width: auto;

        @include m_query(1500px) {
          padding: 2rem 1rem;
        }

        @include m_query(1300px) {
          padding: 2rem 1rem;
        }

        @media screen and (max-width: $breakpoint-m) {
          & {
            // border-bottom: 1px solid rgba(69, 79, 91, .3);
            // justify-content: center;
            width: 90%;

            // &:focus,
            // &:active {
            //   border-bottom: none;
            // }
          }

          // &:last-child {
          //   border-bottom: none;
          // }
        }
      }

      &>li:last-child {
        @include m_query($breakpoint-m, 'min') {
          padding-right: 0 !important;
        }
      }

      &>li:hover {

        @include m_query($breakpoint-m, 'min') {
          .main-header_navbar--item--background ul li {
            opacity: 1 !important;
            visibility: visible !important;
          }

          .main-header_navbar--item--background {
            display: flex !important;
            opacity: 1 !important;
          }

          .main-header_navbar--link {
            color: var(--c-secondary);
          }
        }
      }

    }

    &--item {

      &.focus {
        .collapsible-content {
          display: flex !important;
          opacity: 1 !important;
        }

        ul li {
          opacity: 1 !important;
          visibility: visible !important;
        }
      }

      &--background {
        display: none;

        @include m_query($breakpoint-m, 'min') {
          overflow: hidden !important;
          opacity: 0 !important;
          transition: all 300ms ease-in !important;
        }

      }

      &--background ul li {
        @include m_query($breakpoint-m, 'min') {
          opacity: 0 !important;
          visibility: hidden !important;
          transition: 1s !important;
        }
      }

      @media screen and (max-width: $breakpoint-m) {
        & {
          flex-direction: column;
          width: 436px;

          &>&--background {
            border-top: none;
            box-shadow: none;
            // line-height: 0;
            position: relative;
          }
        }
      }
    }

    &--link {
      color: var(--c-body-primary) !important;
      font-family: var(--ff-secondary);
      font-style: normal;
      font-weight: 500;
      font-size: 15px;

      @include m_query(1500px) {
        font-size: 14px;
      }

      &:hover,
      &:active,
      &:focus {
        color: var(--c-secondary) !important;
      }


    }

    &--item--background {
      background: #F0F4F8;
      border-top: 1px solid var(--c-secondary);
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      left: 0;

      margin: 5rem 0;
      padding: 2rem;
      position: absolute;
      width: 100%;
      z-index: 70;
      top: 0;

        // &::before{
        //   --size: 100%;
        //   content: '';
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   width: 100vw;
        //   height: var(--size);
        //   transform: translate(-50%, -50%);
        //   background: #F0F4F8;

        // }

      &>ul {
        display: flex;
        justify-content: center;
        width: 100%;

        @media screen and (max-width: $breakpoint-m) {
          & {
            flex-direction: column;
          }
        }
      }
    }

    &--card {
      margin: 1rem;
      padding: 1.5rem;
      background: #F0F4F8;
      color: #637381;
      border-radius: 8px;
      width: 280px; //quemado
      height: auto;

      @media screen and (max-width: $breakpoint-m) {
        & {
          width: 100%;
        }
      }

      &>a {
        font-family: var(--ff-secondary);
        font-style: normal;
        font-weight: 600;
        font-size: var(--fs-2);
        color: #637381 !important;
        display: block;
      }

      #arrowMenu {
        fill: #637381;
      }

      &--divImage {
        height: 52px;
        width: 52px;
        background: #FFF;
        border-radius: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 500ms ease;
        margin-bottom: 1rem;
      }

      &:hover {
        background: #FFF;
        box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(20px);

        &>a {
          color: var(--c-secondary) !important;
        }

        #arrowMenu {
          fill: var(--c-secondary) !important;
        }

        & .main-header_navbar--card--divImage {
          background: #DDEBF2;
          transition: all 500ms ease;
        }
      }

      &--descrip {
        margin: 1rem 0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #637381;

        @include m_query($breakpoint-m) {
          flex: 1 0 100%;
          width: 100%;
          padding-left: 3rem;
        }

        @include m_query($boo-sm) {
          padding-left: 2rem;
        }
      }

      &--link {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 16px;
        display: flex !important;
        justify-content: flex-start !important;
        color: #637381;
      }
    }

    &--child_links {
      padding-top: 0.5rem;
      color: #637381;
      font-family: var(--ff-secondary);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;

      &>a {
        color: #637381;
        display: block;

        &:hover {
          color: var(--c-secondary);
        }
      }

      &>img {
        width: 200px;
        height: 80px;
        object-fit: contain;
        border-radius: 8px;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

    }
  }
}

// .main-header_navbar {
//   @include m_query($boo-lg, 'min') {
//     padding: 1rem;
//   }
// }

@media screen and (max-width: $breakpoint-m) {
  .hamburger {
    display: inline-flex !important;
  }

  .menu {
    border-top: 1px solid var(--c-secondary);
    background: #F0F4F8;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 118px;
    left: -100%;
    text-align: center;
    padding: 0;
    display: block;
    transition: all 0.8s;
    z-index: 200;
    padding-bottom: 5rem;

    @include m_query(1280px) {
      top: 75px;
    }

    @include m_query($boo-sm) {
      top: 65px;
    }
  }

  .main-header_navbar--menu {
    flex-direction: column;
    height: fit-content;
  }
}

.menu-active {
  left: 0;
}

.collapsible-content {
  display: none;
}

.collapseToggle {
  display: flex;
}

.onlyThree {
  width: 560px !important;
  height: auto;

  &>h2 {
    display: block;
  }

  &>ul {
    overflow: hidden;
    column-count: 2;

    &>li {
      margin-top: 0 !important;
      padding-top: 0.5rem !important;
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    & {
      width: auto !important;

      &>ul {
        column-count: 1;

        &>li {
          // padding-left: 1.5rem;
        }
      }
    }
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: 00;
    transform: translateY(0);
  }
}

// Animation Hamburguer
#checkbox {
  display: none;
}

.hamburger {
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
}

.line {
  position: absolute;
  width: 20px;
}

.line-main::before,
.line-main::after,
.line-split::before,
.line-split::after {
  content: '';
  position: absolute;
  width: inherit;
  height: 2px;
  background-color: #454F5B;
  transition: transform .5s;
}

.line-main::before {
  transform: translateY(-7px);
}

.line-main::after {
  transform: translateY(7px);
}

.line-split::before,
.line-split::after {
  width: 10px;
  transition: transform .5s, opacity .5s;
}

.line-split::after {
  transform: translateX(10px);
}

#checkbox:checked~.line-main::before {
  transform: rotate(45deg);
}

#checkbox:checked~.line-main::after {
  transform: rotate(-45deg);
}

#checkbox:checked~.line-split::before {
  transform: translateX(-10px);
  opacity: 0;
}

#checkbox:checked~.line-split::after {
  transform: translateX(20px);
  opacity: 0;
}

.menu-container {
  @media screen and (max-width: $breakpoint-m) {
    overflow: auto;
  }

}

.header {

  @include m_query(1280px) {
    position: sticky;
    top: 0;
    background: white;
    z-index: 60;
  }

  &__logo {
    display: flex;
    align-items: center;
  }

  .main-header_navbar_logo {

    img {
      @include m_query(1280px) {
        max-width: 200px;
      }

      @include m_query($boo-sm) {
        max-width: 150px;
      }
    }
  }

  &__icons {
    display: flex;

    @include m_query($boo-xl) {
      margin-left: auto;
      margin-right: 1rem;
    }

    @include m_query($boo-sm) {
      // position: relative;
    }

    >*+* {
      margin-left: 2.5rem;

      @include m_query(1280px) {
        margin-left: 1.5rem;
      }
    }

    &--mobile {
      @include m_query($breakpoint-m, 'min') {
        display: none;
      }

      @include m_query($breakpoint-m) {
        justify-content: center;
        background: white;
        padding: 1rem;
        max-width: 90%;
        margin: 0 auto;
        margin-top: 2rem;
        border-radius: 10px;

        .header__icon-wrapper {
          display: flex;
          flex: 1;
          margin-left: 0 !important;
        }
      }

      @include m_query($boo-sm) {
        flex-wrap: wrap;
        justify-content: flex-start;

        .header__icon-wrapper {
          flex-direction: row;
          flex: initial;
          margin-right: 1rem;
        }
      }
    }

  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    font-family: var(--ff-secondary);
    color: var(--c-body-primary);
    font-size: 15px;
    flex-direction: column;
    justify-content: center;

    @include m_query(1500px) {
      font-size: 14px;
    }

    @include m_query($breakpoint-m) {
      display: none;
    }
  }

  &__icon {
    width: 28px;
    height: 28px;

    // margin-right: 1rem;
    @include m_query($boo-sm) {
      margin-right: 0.4rem;
    }
  }

  &__icon-title {}

  &__bar-top {
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: white;

    @include m_query(1500px, 'min') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__menu {
    background: #FAFAFA;
    position: sticky;
    top: 0;
    z-index: 99;
    transition: var(--g-trans) background, var(--g-trans) box-shadow;
    padding: 0 2rem;

    .main-header_navbar {
      flex-grow: 1;
      transition: var(--g-trans) flex-grow;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // position: relative;

      // min-height: 80px;

      @include m_query(1280px) {
      }

      @include m_query($breakpoint-m, 'min') {
        padding-left: 0;
        padding-right: 0;
      }

      @include m_query($breakpoint-m) {
        max-width: none;
        padding: 0 1rem;
      }


    }

    .header__logo {
      visibility: hidden;
      opacity: 0;
      transition: var(--g-trans) .1s opacity, var(--g-trans) .1s visibility, var(--g-trans) width;
      width: 0;

      // transition: .6s all;
      @include m_query(1280px, 'min') {
        position: absolute;
        left: 0;
        margin-left: 4rem;
      }


      @include m_query(125rem, 'min') {
        position: static;
      }


      @include m_query($breakpoint-m) {
        display: none;
      }

      img {
        width: 12rem;
        opacity: 0;
      }
    }

    &.is-sticky {
      background: white;
      box-shadow: 0 10px 24px rgba(69, 79, 91, .08);

      .header__logo {
        visibility: visible;
        opacity: 1;
        width: 190px;
        // width: auto;

        img {
          opacity: 1;
        }
      }

      .main-header_navbar--menu {

      }

      .main-header_navbar {
        @include m_query(1280px) {
          flex-grow: 0;
        }
      }
    }
  }
}

.form-search {
  display: flex;
  align-items: center;

  @include m_query($boo-sm) {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: var(--g-trans) opacity, var(--g-trans) visibility;
    pointer-events: none;
    user-select: none;
    padding: 0.8rem 1rem;
    background: aliceblue;
    margin: 0;
    width: 100%;
  }

  &.active {
    visibility: visible;
    opacity: 1;
    user-select: initial;
    pointer-events: initial;
  }

  &__text {
    height: 44px;
    border: 2px solid #DDEBF2;
    border-radius: 5px;
    color: var(--c-body-primary);
    padding: 0.5rem 3rem 0.5rem 1rem;

    &::placeholder {
      color: #9CAFB8;
    }

    @include m_query($boo-sm) {
      flex: 1;
      max-width: calc(100% - 3rem);
    }
  }

  &__submit {
    margin-left: -44px;
    height: 44px;
    width: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--g-trans) background;

    @include hover {
      background: var(--c-gray-light);
    }
  }

  &__btn-toggle {

    @include m_query($boo-sm, 'min') {
      display: none;
    }
  }

  &__close {
    width: 44px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    align-items: center;
    z-index: 10;
    right: 1rem;
    position: absolute;
    height: 44px;
    visibility: hidden;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transition: var(--g-trans) visibility, var(--g-trans) opacity;

    @include m_query($boo-sm, 'min') {
      display: none;
    }

    &.active {
      visibility: initial;
      opacity: 1;
      user-select: inherit;
      pointer-events: inherit;
    }
  }
}

/*————————————————————————————————————————————————————*\
    ●❱ MENU RESPONSIVE
\*————————————————————————————————————————————————————*/

@include m_query($breakpoint-m) {

  .main-header {
    &_navbar {

      &--menu {
        text-align: left;

        @include m_query($boo-sm) {
          padding-top: 1.5rem;
        }

        >li {
          justify-content: flex-start;
          align-items: flex-start;

          @include m_query($boo-sm) {
            padding: 1rem 0.5rem;
          }
        }
      }

      &--item {

        @include m_query($boo-sm) {
          padding: 1rem 0;
        }

        &--background {
          background: white;
          border-radius: 5px;
          margin-top: 0.5rem;
          margin-bottom: 0;
          padding: 2rem;

          @include m_query($boo-sm) {
            padding: 1.5rem;
          }
        }
      }

      &--link {
        font-size: var(--fs-2);

        &.collapsible {
          width: 100%;
          position: relative;
          display: block;
          text-align: left;

          &::after {
            --size: 14px;
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: var(--size);
            height: var(--size);
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.29492 5.97532L11.1278 0.980469L12.5083 2.40728L6.29492 8.82895L0.0815433 2.40728L1.46208 0.980468L6.29492 5.97532Z' fill='%23707882'/%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            transition: var(--g-trans) transform;
            transform-origin: top;
          }

          &.active {
            &::after {
              transform: rotate(180deg);
            }
          }
        }
      }

      &--child_links {
        margin-top: 0;
        padding-left: 3rem;

        @include m_query($boo-sm) {
          padding-left: 2rem;
        }

        >a {
          @include m_query($boo-sm) {
            font-size: 14px;
          }
        }
      }

      &--card {
        background: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        @include hover {
          box-shadow: none;
        }

        &+* {
          margin-top: 1rem;
        }

        >a {
          font-size: var(--fs-1);
          font-weight: 400;
          display: flex;
          align-items: center;
        }

        >ul {
          flex: 1 1 100%;
          max-width: 100%;
          border-top: 1px solid #D9D9D9;
          padding-top: 1rem;
          margin-top: 1rem;

          @include m_query($boo-sm) {
            padding-top: 0.5rem;
            margin-top: 0.5rem;
          }
        }

        &--divImage {
          margin-bottom: 0;
          --size: 32px;
          width: var(--size);
          height: var(--size);
          margin-right: 1rem;

          @include m_query($boo-sm) {
            --size: 20px;
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
}

.js-consulte-aqui {
  @include m_query($breakpoint-m) {
    display: none !important;
  }
}