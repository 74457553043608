.sidebar {
  background-color: #FFFFFF;
  border-radius: 1rem;
  height: fit-content;
  min-height: 320px;
  padding: 2rem;

  @include m_query($boo-md) {
      min-height: 240px;
  }
}

.widget {
  max-height: 20rem;
  overflow-y: auto;

  &_title {
    // border-bottom: 1px solid #D7E1EA;
    font-family: var(--ff-secondary);
    font-style: normal;
    font-weight: 600;
    font-size: var(--fs-2);;

    // padding-bottom: 0.5rem;
    margin-bottom: 0.3rem;
  }

  &_content {
    &_title {
      font-family: var(--ff-secondary);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;

    }

    &_filter {
      margin: 0;
      padding: 0;

      &_item {
        font-family: var(--ff-primary);
        font-style: normal;
        font-size: 16px;

        &_mes {
          font-family: var(--ff-primary);
          font-style: normal;
          font-size: 16px;

          &.active, &:hover {
            background-color: #E7F3FF;
  
            .widget_content_filter_link {
              color: var(--c-secondary);
            }
          }
        }

        &.active, &:hover {
          background-color: #E7F3FF;

          .widget_content_filter_link {
            color: var(--c-secondary);
          }
        }
      }

      &_link {
        color: #454F5B;
        display: block;
        padding: 5px 10px;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}


.sc-with-sidebar {

  flex-direction: row;

  @media only screen and (max-width: 1024px) {
    & {
      flex-direction: column;
    }
  }

  &__mained {

    .container {
      width: auto;
    }

    > *:last-child{
      margin-bottom: var(--sp-40);
    }

  }

  &__sidebar {
    padding: 3rem 3rem;
    background: white;
    box-shadow: 0 16px 40px rgba(112, 144, 176, 0.2);
    flex: 0 1 500px;

    @include m_query(1024px) {
      width: 100% !important;
    }

    @include m_query($boo-sm) {
        padding: 2rem 1.5rem;
        // margin-top: 2rem;
    }
  }
}

/*————————————————————————————————————————————————————*\
    ●❱ WIDGET 'LINKS'
\*————————————————————————————————————————————————————*/

.widget {
  &__title {
    font-size: var(--fs-3);
  }
}

.line-break {
  // border-bottom: 1px solid #E8E8E8;
  // margin: 2rem;
  // height: 1px;
  display: none;
}

.widget-links {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  &__items {
    margin-top: 1.5rem;
    font-family: var(--ff-secondary);
    font-size: 16px;
    font-weight: 600;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  li {
    position: relative;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.25rem;
  }

  li + li {
    margin-top: 0.8rem;
  }

  a {
    color: var(--c-primary);
    font-weight: 500 !important;

    @include hover {
      color: var(--c-secondary-dark);
    }
  }
}

/*————————————————————————————————————————————————————*\
    ●❱ WIDGET 'BUTTON'
\*————————————————————————————————————————————————————*/

.widget-button {
  display: block;
  position: relative;
  padding: 3rem 2rem;
  overflow: hidden;
  background: #F3F8FB;
  border-radius: 4px;
  transition: .3s filter, .3s transform;
  width: 100%;
  margin-bottom: 1rem;

  @include hover {
    filter: saturate(1.2);
    transform: scale(1.05);
  }

  &__bg {
    position: absolute;
    inset: 0;
    pointer-events: none;

    > * {
      @include m_query(1024px) {
        width: 100%;
      }
    }
  }

  &__ornament {
    max-width: initial;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__ornament2 {
    position: absolute;
    left: 55%;
    top: 0;
    transform: scaleX(-1);
    max-width: none;
  }

  &__icon {
    position: relative;
    margin: 0 auto;
    width: 65px;
    height: 65px;
    object-fit: contain;
    margin-bottom: 1.5rem;
  }

  &__title {
    position: relative;
    text-align: center;
    font-size: var(--fs-2);
    font-weight: 600;
    font-family: var(--ff-secondary);
    color: #454F5B;
  }

  &--blue {
    .widget-button {
      &__bg {
        filter: hue-rotate(25deg);
      }
    }
  }
}

.sidebar--with-filter{

  > .container{
    @include m_query($boo-lg) {
        flex-wrap: wrap;
    }
  }
  aside{
    @include m_query($boo-lg) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.loading:empty {
  top: calc(50%);
  left: calc(50%);
  width: 4rem;
  padding: 0rem;
  height: 4rem;
  border: 1rem solid rgba(33, 186, 179, .7);
  border-left: 1rem solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}