.counter {
    background: linear-gradient(161.21deg, #24CCC4 0%, #005F86 62.54%), linear-gradient(180deg, var(--c-secondary) 0%, #005F86 100%);
    background-size: 42%, 100%;
    background-position: right, center;
    background-repeat: no-repeat;
    padding: var(--sp-80) 0;
    color: white;
    font-family: var(--ff-secondary);
    margin-bottom: 3rem;


    &__title-wrapper {
        height: 100%;
        text-align: center;
        margin-bottom: var(--sp-64);
    }

    &__title {
        font-size: var(--fs-4);
        margin-bottom: 0.5rem;
    }

    &__subtitle {}

    &__item {
        padding: var(--sp-64) var(--sp-40);
        background: rgba(255, 255, 255, 0.15);
        text-align: center;
        font-weight: 700;
        height: 100%;
    }

    &__info-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid;
        padding-bottom: 1rem;
    }

    &__number-wrapper {
        font-weight: normal;
        display: flex;
        align-items: center;

        @include font-size(2rem, 4rem);
    }

    &__number {}

    &__number-postfix {
        font-size: 0.6em;
    }

    &__info-postfix {
        font-size: var(--fs-2);
        padding-left: 1rem;
        text-align: left;
        line-height: 1.1;
    }

    &__info-title {}
}