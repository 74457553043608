/*  |> BREAKPOINTS BOOSTRAP
——————————————————————————————————————————————————————*/

$boo-sm: 36em;
$boo-md: 48em;
$boo-lg: 62em;
$boo-xl: 75em;

$enable-button-pointers: true !default;

$kbd-padding-y: .5rem;
$kbd-padding-x: .5rem;
$kbd-color: grey;
$kbd-bg: white;

:root {
    --p-container: 7rem; //Padding Container
    --max-width-container: calc(1320px + var(--p-container)); // Add the corresponding padding used to compensate for the width.

    /*
    ——— Transition duration time
    */
    --g-trans: .6s;

    /*
    ——— Fonts
   */
    --ff-primary: "Lato", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --ff-secondary: "Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --ff-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --body-font-size: 1rem;
    --body-line-height: 1.5;

    /*
   ——— Colors
   */
    --c-primary: #005F86;
    --c-primary-dark: #1B5173;
    --c-primary-light: #E7F3FF;

    --c-secondary: #21BAB3;
    --c-secondary-dark: #00A499;

    --c-body-primary: #454F5B;
    --c-body-bg: white;

    --c-gray-light: #D7E1EA;

    /*
   ——— HR
   */
    --hr-color: grey;
    --hr-opacity: 1;

    /*
   ——— Mark
   */
    --mark-padding: .5rem;
    --mark-bg: yellow;

    /*
   ——— Links
   */
    --link-color: var(--c-secondary);
    --link-decoration: var(--c-secondary);
    --link-hover-color: var(--c-secondary-dark);
    --link-hover-decoration: var(--c-secondary-dark);
}