/*————————————————————————————————————————————————————*\
    ●❱ BASIC GRID - Inspired in Bootstrap
\*————————————————————————————————————————————————————*/

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

.col {
    flex: 1 0 0%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

/*  |> GUTTERS
——————————————————————————————————————————————————————*/

.g-0,
.gx-0 {
    --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0;
}

.g-32 {
    --size: 2rem;
    --bs-gutter-y: var(--size);
    --bs-gutter-x: var(--size);

    @media screen and (max-width: $boo-sm){
        --size: 1rem;
    }

}

.gy-24 {
    --bs-gutter-y: 1.5rem;
}

@media screen and (max-width: $boo-lg){
    .col-3,
    .col-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}


@media screen and (max-width: $boo-sm)
{
    .col-3,
    .col-4,
    .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*————————————————————————————————————————————————————*\
    ●❱ SPACING UTILITIES
\*————————————————————————————————————————————————————*/

.py-100{
    padding-top: var(--sp-100);
    padding-bottom: var(--sp-100);
}

.pb-100{
    padding-bottom: var(--sp-100);
}

.pt-100{
    padding-top: var(--sp-100);
}


.pt-64{
    padding-top: var(--sp-64);
}