.hero-page {
    position: relative;
    padding: 2rem 0;
    min-height: 18.75rem;

    @include m_query($boo-sm) {
        padding-top: 0;
        background: var(--c-primary);
    }

    .container {
        position: relative;
    }

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--c-primary);
        overflow: hidden;

        @include m_query($boo-sm) {
            position: relative;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right;

        @include m_query($boo-sm) {
            height: 8rem;
    margin-bottom: 1.5rem;
    object-position: right;
        }
    }

    &__overlay {
        position: absolute;
        width: 80%;
        height: 110%;
        inset: 0;
        background: linear-gradient(100.05deg, #005F86 34.94%, rgba(0, 157, 150, 0.4) 63.98%);
        backdrop-filter: blur(10px);
        -webkit-mask-image: linear-gradient(90deg, #000000 50%, rgba(0, 0, 0, 0) 80%);

        @include m_query($boo-md) {
            width: 120%;
        }

        @include m_query($boo-sm) {
            // width: 170%;
            display: none;
        }
    }

    &__content {
        color: white;
        max-width: 520px;
        min-height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include m_query($boo-sm) {
            min-height: auto;
        }
    }

    &__title {
        font-size: var(--fs-6);
        margin-bottom: 0.5rem;
        font-weight: 700;
    }

    &__subtitle {
        font-family: var(--ff-secondary);

        font-weight: 600;
        font-size: var(--fs-2);

        margin: 1rem 0px;
    }

    &__description {
        font-size: var(--fs-1);
        margin-bottom: 1.5rem;
    }

    &__btn {
        margin-right: auto;
    }
}


.breadcrumb {
    font-size: 14px;
    font-family: var(--ff-secondary);

    a{
        color: white;

        @include hover{
            color: white;
            text-decoration: underline;
        }
    }

    ul {
        display: flex;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0.2rem;
    }

    li {}

    li+li {
        margin-left: 20px;
        position: relative;

        &::before {
            --size: 10px;
            content: '';
            position: absolute;
            top: 50%;
            left: -8px;
            width: var(--size);
            height: var(--size);
            transform: translate(-50%, -50%);
            background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.125 5.30333L0 1.17833L1.17833 0L6.48167 5.30333L1.17833 10.6067L0 9.42833L4.125 5.30333Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}