.mc-icon-boxes {

  .row-content {
    justify-content: center;
    margin-top: var(--sp-40);
  }

  &__item {
    background: #F3F8FB;
    position: relative;
    padding: 2rem 2.5rem var(--sp-64);
    overflow: hidden;
    height: 100%;

    @include m_query($boo-sm) {
      padding: 2rem 1.5rem var(--sp-64);
    }
  }

  &__bg {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  &__ornament {
    width: 100%;
    height: auto;
  }

  &__ornament-2 {
    position: absolute;
    top: 0;
    left: 60%;
    transform: scaleX(-1);
  }

  &__icon {
    position: relative;
    margin-left: auto;
    margin-bottom: var(--sp-64);

    @include m_query($boo-sm) {
      width: 40px;
      margin-bottom: 1rem;
    }
  }

  &__title {
    position: relative;
    font-size: var(--fs-2);
    margin-bottom: 0.5rem;
  }

  &__description {
    position: relative;

    ul {
      margin: 0;
      padding-left: 1.2rem;

      li {
        margin-bottom: 0.875rem;
        position: relative;

        &:before {
          content: "✓";
          color: var(--c-secondary);
          position: absolute;
          left: -1.3rem;
        }
      }
    }
  }
}