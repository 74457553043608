.current_contract {

  &_wrap {
    background-color: #FFFFFF;
    border-radius: 1rem;
    padding: 2.75rem;

    @include m_query($boo-sm) {
      padding: 0;
      overflow: scroll;
    }
  }

  &_header {
    &_row {
      border-bottom: 1px SOLID #D7E1EA;
    }

    &_item {
      color: #454F5B;
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: 1rem;

      padding-bottom: 10px;
      text-align: center;

      &:first-child {
        text-align: start;
        width: 50%;

        @include m_query($boo-lg) {
          width: 50%;
        }
      }
    }
  }

  &_body {
    &_row {
      border-bottom: 1px SOLID #D7E1EA;

      &:last-child {
        border: none;
      }
    }

    &_item {
      padding: 1.7rem 0;
      text-align: center;

      &:first-child {
        text-align: start;
      }
    }

    &_link {
      color: #454F5B;
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: 1rem;

      @include m_query($boo-sm) {
        font-weight: 400;
      }


      &:hover {
        color: var(--c-secondary);
      }

    }
  }

}


/*————————————————————————————————————————————————————*\
    ●❱ RESPONSIVE TABLES
\*————————————————————————————————————————————————————*/

@include m_query($boo-sm) {
  .responsive-table {

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      // border: 1px solid #ccc;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;

      &:nth-child(even) {
        background: #FAFAFA;
      }
    }

    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding: 2rem .4rem .5rem;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        flex: 1 1 50%;;
      }
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    td::before {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
    }

    /*
	Label the data
	*/
    td:nth-of-type(1):before {
      content: "Descripción";
    }

    td:nth-of-type(2):before {
      content: "Fecha/hora de apertura";
    }

    // td:nth-of-type(3):before {
    //   content: "Hora de apertura";
    // }

    td:nth-of-type(4):before {
      content: "Año";
    }
  }
}