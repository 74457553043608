.form_property {

  &_wrap {
    border: 1px solid rgba(215, 225, 234, 1);
    padding: 1.5rem 2.5rem;

    @include m_query($boo-sm) {
      padding: 1.5rem;
    }
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-2);
    color: #454F5B;
  }

  &_label {
    display: block;
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 12px;
    color: #454F5B;
    margin-bottom: 0.5rem;
  }

  &_select {
    background: #F0F4F8;
    border-right: 12px solid transparent;
    border-radius: 30px;
    padding: 5px 12px;
    font-family: var(--ff-primary);
    font-size: 14px;
    color: #454F5B;

    //-webkit-appearance: none;
    //appearance: none;

    &::after {
      content: "▼";
      font-size: 1rem;
      top: 6px;
      right: 10px;
      position: absolute;
    }
  }

  &_submit {
    min-width: auto;
    // background: var(--c-secondary);
    // border: 2px solid var(--c-secondary);
    // border-radius: 4px;
    // color: #FFFFFF;
    // padding: 1rem 2.5rem;
    // width: 100%;

    // &:hover {
    //   color: #FFFFFF;
    //   opacity: 0.8;
    // }
  }
}

/*————————————————————————————————————————————————————*\
    ●❱ MODAL
\*————————————————————————————————————————————————————*/


.ct-form {
  min-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container{
    max-height: 80%;

    @include m_query($boo-sm) {
    max-height: 90%;

    }
  }

  .row-content {
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
  }

  .col-content{
    height: 100%;
  }

  .col-img {
    @include col(4);

    @include m_query(1024px) {
      display: none;

    }
  }

  &__img-wrapper {
    height: 100%;
    position: relative;
  }

  &__gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 23.56%, rgba(0, 0, 0, 0) 63.5%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  &__img {
    height: 100%;
    object-fit: cover;
  }

  &__content {
    padding: 2.5rem;
    background: white;
    height: 100%;
    overflow: auto;

    @include m_query($boo-sm) {
      padding: 1.5rem;
    }
  }

  &__btn-close {
    margin-left: auto;
    display: block;
  }

  &__title {
    font-size: var(--fs-3);
    margin-bottom: 1.5rem;
    width: 100%;

    @include m_query($boo-sm) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__form-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    width: 100%;

    @include m_query($boo-sm) {
      grid-template-columns: 1fr;
      gap: 1rem;

    }
  }

  &__field {

    display: flex;
    width: 100%;
    flex-direction: column;

    &[data-width="100"] {
      @include m_query($boo-sm, 'min') {
        grid-column: 1 / 3;
      }
    }

    label,
    legend {
      font-family: var(--ff-secondary);
      font-weight: 600;
      margin-bottom: 0.2rem;
      font-size: 14px;
    }

    input,
    textarea {

      &::placeholder {
        color: #A1ABB6;
      }
    }

    input,
    textarea,
    select {
      min-height: 44px;
      border: 1px solid #D7E1EA;
      padding: 0.5rem 1rem;
      color: #454F5B;
      width: 100%;
    }

    &--radio {

      ul {
        display: flex;
        padding: 0;
        gap: 0.5rem;
        margin-bottom: 0;
        flex-wrap: wrap;
      }

      input[type="radio"] {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 2px solid gray;
        outline: none;
        -webkit-appearance: none;
        appearance: none;
        margin-right: 0.3rem;
        cursor: pointer;
        position: relative;
        top: 5px;
        padding: 0;
        min-height: auto;

        &:focus {
          outline: 2px dashed var(--c-primary-dark) !important;
        }

        &::before {
          display: block;
          content: " ";
          width: 10px;
          height: 10px;
          border-radius: 6px;
          background-color: #257eb0;
          font-size: 1.2em;
          transform: translate(3px, 3px) scale(0);
          transform-origin: center;
          transition: all 0.3s ease-in;
        }

        &:checked::before {
          transform: translate(3px, 3px) scale(1);
          transition: all 0.3s cubic-bezier(0.25, 0.25, 0.56, 2);
        }

      }
    }

    &--file {
      input[type="file" i] {
        font-size: 0.9rem;
      }

      input[type="file" i] {

        /* For Webkit */
        // &::-webkit-file-upload-button {
        //   padding: 0.5rem 1.5rem;
        //   border-radius: 5px;
        //   background: black;
        //   color: white;
        //   border: none;
        //   margin-right: 1rem;
        //   cursor: pointer;
        //   transition: .3s all;
        // }

        // &::-webkit-file-upload-button:hover{
        //   background: #484848;
        // }

        /*For Firefox and Webkit  */
        &::file-selector-button {
          padding: 0.5rem 1.5rem;
          border-radius: 5px;
          background: #637381;
          color: white;
          border: none;
          margin-right: 1rem;
          cursor: pointer;
          transition: .3s all;
        }

        &::file-upload-button:hover{
          background: #484848;
        }

        >label {}
      }
    }
  }

  &__notes {
    ul {
      padding: 0;
      margin: 1rem 0;

      li {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: var(--c-body-primary);
        margin-bottom: 0.5rem;

        &:before {
          content: "*";
          margin-right: 8px;
        }
      }

    }
  }

  &__message {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: 2s all;
    height: 100%;

    &-text {
      font-size: var(--fs-3);
      text-align: center;
    }
  }
}

/* FOR POPUP */
[data-popup-dest],
[class*="data-popup-dest--"] {
  visibility: hidden;
  opacity: 0;
  transition: 1s all;
  background: rgba(0, 0, 0, 0.75);
  // padding: 5rem 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  backdrop-filter: blur(20px);
  pointer-events: none;
  user-select: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 576px) {

  [data-popup-dest],
  [class*="data-popup-dest--"] {
    // justify-content: flex-start;
  }
}


.show--pop-up {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  user-select: auto;
}

.ct-form__field--file input[type=file i]::file-selector-button:hover, .ct-form__field--file input[type=file i]:focus::file-selector-button{
  background: var(--c-primary-dark);
}

.float-button{
  padding: 0.5rem 2rem;
  padding-left: 3rem;
  background: white;
  border-radius: 5px 0 0 5px;
  max-width: 240px;
  box-shadow: 0 4px 24px rgb(145 158 171 / 40%);
  font-family: var(--ff-secondary);
  position: fixed;
  right: -15px;
  z-index: 110;
  transform: translateX(calc(100% - 3.7rem));
  transition: var(--g-trans) transform, var(--g-trans) background;
  text-align: left;

    &::before{
      --size: 20px;
      content: '';
      position: absolute;
      top: 50%;
      left: 1.5rem;
      width: var(--size);
      height: var(--size);
      transform: translate(-50%, -50%);
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.3729 0H3.11305C1.39659 0 0 1.39659 0 3.11305V14.189C0 15.9055 1.39659 17.302 3.11305 17.302H22.3729C24.0894 17.302 25.486 15.9055 25.486 14.189V3.11339C25.4863 1.39659 24.0897 0 22.3729 0ZM20.8517 2.03092L12.743 7.25511L4.63421 2.03092H20.8517ZM22.3729 15.2715H3.11305C2.5163 15.2715 2.03092 14.7857 2.03092 14.1893V3.17094L12.1164 9.65632C12.13 9.66479 12.1445 9.67054 12.1584 9.67833C12.173 9.68645 12.1879 9.69423 12.2028 9.70168C12.2809 9.74196 12.3615 9.77446 12.4438 9.79578C12.4522 9.79815 12.4607 9.79916 12.4691 9.8012C12.5595 9.82252 12.6509 9.83572 12.7423 9.83572C12.7426 9.83572 12.743 9.83572 12.743 9.83572C12.7437 9.83572 12.744 9.83572 12.7443 9.83572C12.8357 9.83572 12.9271 9.82286 13.0175 9.8012C13.026 9.79916 13.0344 9.79815 13.0429 9.79578C13.1251 9.77446 13.2053 9.74196 13.2839 9.70168C13.2988 9.69423 13.3137 9.68645 13.3282 9.67833C13.3421 9.67054 13.3567 9.66479 13.3702 9.65632L23.4557 3.17094V14.189C23.4554 14.7857 22.9697 15.2715 22.3729 15.2715Z' fill='%2321BAB3'/%3E%3C/svg%3E%0A");
      background-position: center;
    }

    @include hover{
        background: #f8f8f8;
        transform: translateX(0);
    }

  &__top{
    top: 15rem;
  }
  &__bottom{
    bottom: 10rem;
  }
}