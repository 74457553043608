.btn-link {
  font-weight: 600;
  position: relative;
  display: inline-flex;

  &::after {
    --size: 100%;
    content: "";
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    width: calc(var(--size) - 1.8rem);
    height: 1px;
    background: white;
  }

  .icon {
    margin-left: 0.5rem;
    filter: invert(1);
  }
}

.btn-green {
  background: var(--c-secondary);
  border: 2px solid var(--c-secondary);
  border-radius: 4px;
  color: #FFFFFF;
  padding: 1rem 2.5rem;
  width: fit-content;

  &:hover {
    color: #FFFFFF;
    opacity: 0.8;
  }
}

.btn-track {
  background: var(--c-secondary);
  border: 2px solid var(--c-secondary);
  border-radius: 4px;
  color: #FFFFFF;
  padding: 1rem 1.25rem;

  &:hover {
    color: #FFFFFF;
    opacity: 0.8;
  }
}

.btn-track-blur {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.01);;
  border-radius: 4px;
  backdrop-filter: blur(40px);
  display: flex;
  height: 45px;
  justify-content: center;
  transform: matrix(1, 0, 0, -1, 0, 0);
  width: 45px;
}

.btn-internal-slider {
  align-items: center;
  background-color: #FFF;
  border-radius: 4px;
  display: flex;
  height: 45px;
  justify-content: center;
  transform: matrix(1, 0, 0, -1, 0, 0);
  width: 45px;
}

.btn-exchange {
  background: var(--c-secondary);
  padding: 1rem;
}


.btn-primary {
  padding: 1rem 2.5rem;
  background: var(--c-secondary);
  color: white;
  border-radius: 4px;
  min-width: 220px;
  display: inline-block;
  text-align: center;
  font-family: var(--ff-secondary);
  transition: .3s background, .3s color;

  @include hover {
    background: var(--c-secondary-dark);
    color: white;
  }

  @include m_query($boo-sm) {
    min-width: 160px;
    padding: 0.5rem 1.5rem;
    font-size: 14px;
  }
}

.btn--white {
  background: white;
  color: #454F5B;

  @include hover {
    background: var(--c-secondary-dark);
    color: white;
  }
}

.btnQuery {
  display: flex;
  padding: 1.5rem 1rem;
  align-items: center;
  z-index: 100;
  width: auto;
  gap: 15px;
  height: 48px;
  top: 15rem;
  position: fixed;
  transform: translateX(85px);
  right: 0;
  border-style: none;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, .3);
  transition: all 600ms ease;

  &-label {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  &:hover {
    transform: translateX(0px);
    transition: all 600ms ease;
  }
}