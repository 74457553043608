.wrapper-nav-article {
  @apply text-sm font-medium text-center border-b;
  @layer text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-70;
}

.column-list {
  flex-direction: column;
  row-gap: 1rem;
  text-align: left;
}


.article-list {
  column-gap: 2rem;
  display: flex;
  font-family: var(--ff-secondary);

  font-weight: 500;
  font-size: 16px;

  margin: 0;
  padding: 0;

  .article-item {

    &_link {
      color: #454F5B;
      padding-bottom: 0.5rem;

      &:hover {
        border-bottom: 2px SOLID var(--c-secondary);
        color: var(--c-secondary);

      }

    }

    &.active {

      .article-item_link {
        border-bottom: 2px SOLID var(--c-secondary);
        color: var(--c-secondary);
      }
    }


  }
}

.category_document {

  &:last-child{
    .document_list{
      margin-bottom: 0;
    }
  }

  &_detail {
    padding-bottom: 2.25rem;

    &_title {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      column-gap: 0.625rem;
      margin-bottom: 0;
      text-transform: capitalize;
    }

    &_description {
      font-family: var(--ff-primary);
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 1rem;
    }
  }
}


.document {
  &_list {
    background-color: #FFFFFF;
    border-radius: 1rem;
    padding: 1rem 2.75rem;
    margin-bottom: 3rem;
    transition: var(--g-trans) box-shadow, var(--g-trans) color;

    @include hover{
      box-shadow: 0 0 60px 0 rgba(0, 0, 0, .12);
    }

    @include m_query($boo-sm) {
        padding: 0 1rem;
        // margin-bottom: 0;
    }
  }

  &_item {
    border-bottom: 1px SOLID #D7E1EA;
    // padding: 30px 0;
    font-family: var(--ff-primary);
    color: #454F5B;

    @include m_query($boo-sm) {
        padding: 0.5rem 0;
    }

    &_link {
      align-items: center;
      color: #454F5B;
      column-gap: 30px;
      display: flex;
      justify-content: space-between;
      padding: 1.8rem 0;
      transition: var(--g-trans) box-shadow, var(--g-trans) color;
      position: relative;

        // &::before{
        //   --size: 100%;
        //   content: '';
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   width: calc(100% + 2rem);
        //   height: var(--size);
        //   transform: translate(-50%, -50%);
        //   background: white;
        //   transition: var(--g-trans) box-shadow, var(--g-trans) color;
        //   border-radius: 10px;
        // }

      @include hover{
        // z-index: 10;
        .document_item_txt-bold, .document_item_info_size{
          color: var(--c-secondary-dark);
        }

          // &::before{
          //   box-shadow: 0 0 60px 0 rgba(0, 0, 0, .12);
          // }

          .document_item_info img{
            filter: brightness(0) saturate(100%) invert(43%) sepia(61%) saturate(1626%) hue-rotate(144deg) brightness(90%) contrast(101%);
          }
      }


    }

    &_content {
      width: 80%;
      position: relative;
    }


    &_info {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 20%;
      position: relative;

      img{
        width: 30px;
      }

      &_size {
        font-family: var(--ff-primary);
        font-weight: 900;
        font-size: 16px;
        color: #454F5B;

        @include m_query($boo-sm) {
            // font-size: 0.8rem;
            display: none;
        }
      }
    }

    &_txt-bold {
      font-family: var(--ff-primary);
      font-weight: 900;
      font-size: 1rem;
      color: #454F5B;

      @include m_query($boo-sm) {
          font-size: 0.8rem;
      }
    }

    &_txt-normal {
      font-family: var(--ff-primary);
      font-size: 14px;
      color: #454F5B;
      display: block;
    }

    &:last-child {
      border: none;
    }

  }
}
