.tabs {
  @include m_query($boo-md) {
      padding-top: 0 !important;
  }
  &_list {
    list-style: none;
    margin-bottom: 0;
    display: flex;
    padding-top: 1rem;

    & > .tabs_lin {
      border-radius: 15px 15px 0px 0px !important;

      &.active {
        background: #FFF !important;
      }
    }
  }

  &_li, .tabs_lin{
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 3rem;
    text-align: center;
    width: 33.33%;
    font-family:var(--ff-secondary);
    font-weight: 600;
    font-size: 1rem;

    color: #FFF;

    @include m_query($boo-sm) {
      min-height: 3rem;
      flex: 1 0 auto;
      width: auto;
    }

    & .link_tab {
      font-family: var(--ff-primary);

      font-weight: 900;
      font-size: 15px;
      color: #FFF !important;
      padding: .5rem 1rem;
      width: 100%;
      font-weight: 900;

      @include m_query($boo-sm) {
          font-size: .8rem;
          padding: 0.4rem 1rem;
      }
    }

    &.active {
      background: #E7F3FF;
      color: var(--c-secondary);
      font-size: var(--fs-1);


      & .link_tab  {
        color: #005F86 !important;
        font-weight: 900;
      }
    }
  }

  &_content {
    background-color: #fff;
    padding: var(--sp-40);

    @include m_query($boo-sm) {
      padding-left: 0;
      padding-right: 0;
    }

    &_wrap {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 2.5rem;
      margin-top: 1rem;

      @include m_query($boo-lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include m_query($boo-sm) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

      }
    }
  }

  &--compact{
    padding: 1rem 0 0 0;
    .tabs{
      /*Not include styles here. It will not working. */
      &_li{
        width: auto;
        min-width: 180px;
        padding: 0 2rem;
        font-size: var(--fs-2);
      }
    }
  }
}

.tab-component-container{
  top: 126px;

  @include m_query($boo-xl) {
      top: auto;
      overflow: auto;
  }
}