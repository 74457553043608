.ta_graphics {
    padding: var(--sp-80) 0;
    background: var(--gd-blue-1);
    background-size: 42%, 100%;
    background-position: right, center;
    background-repeat: no-repeat;
    color: white;
    &__item {
        padding: var(--sp-40);
        background: rgba(255, 255, 255, 0.1);
        height: 100%;
        text-align: center;
    }

    &__title {
        font-size: var(--fs-4);
        margin-bottom: 1.5rem;
    }

    &__content {
        font-size: var(--fs-1);

        > * + *{
            margin-top: 1.5rem;
        }

        h3{
            font-size: var(--fs-2);
        }
    }

    &__graphic {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        svg{
            @include m_query($boo-sm) {
                width: 100%;
            }
        }
    }
}