
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--ff-secondary);
    font-weight: 600;
    line-height: calc(1em + 0.5rem);
    margin-bottom: 0;
}

a,
p {
    line-height: calc(1em + 0.5rem);
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    @include visually-hidden;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}

.skip-link {
    background: var(--c-primary-dark);
    padding: 0.8rem;
    width: 100%;
    display: block;
    text-align: center;
    color: white!important;
    font-size: 1.3rem;
    position: fixed;
    top: 0;
    z-index: 10000;
    opacity: 1;
    font-weight: 700;
    transition: var(--g-trans) opacity;
    text-decoration-color: white;


    &:focus-within,
    &:focus {
        opacity: 1;
    }
}


/*  |> RESPONSIVE PROPERTIES
——————————————————————————————————————————————————————*/

:root {
    scroll-behavior: smooth;
    /* Font sizes */
    @include rfs("--fs-1", 1rem, 1.125rem); //18px
    @include rfs("--fs-2", 1.125rem, 1.25rem); //20px
    @include rfs("--fs-3", 1.25rem, 1.5rem); //24px
    @include rfs("--fs-4", 1.5rem, 1.875rem); //30px
    @include rfs("--fs-5", 1.5rem, 2rem); //34px
    @include rfs("--fs-6", 1.5rem, 2.375rem); //40px
    // @include rfs("--fs-7", 2.5rem, 3.25rem); //52px

    /* Spacing */
    @include rfs("--sp-40", 2rem, 2.5rem); //40px
    @include rfs("--sp-64", 2rem, 4rem); //64px
    @include rfs("--sp-80", 2.5rem, 5rem); //80px
    @include rfs("--sp-100", 2.5rem, 6.25rem); //100px


    /* Border Radius */
    @include rfs("--br-15", 8px, 15px); //15px

    /*
    ——— Gradients
    */
   --gd-blue-1: linear-gradient(161.21deg, #24CCC4 0%, #005F86 62.54%), linear-gradient(180deg, #21BAB3 0%, #005F86 100%);

    /*
    ——— Icons
    */
   --icon-arrow: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M19 7L11.7929 14.2071L10.7071 13.2929L4.41421 7L3 8.41421L11.7071 17.1213L20.4142 8.41421L19 7Z' fill='black'/%3e %3c/svg%3e");
   --icon-arrow-slider: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.36728 8.29994L0.592285 14.0749L2.24195 15.7246L9.66662 8.29994L2.24195 0.875276L0.592285 2.52494L6.36728 8.29994Z' fill='%2321BAB3'/%3E%3C/svg%3E%0A");
   --icon-arrow-slider--white: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.36728 8.29994L0.592285 14.0749L2.24195 15.7246L9.66662 8.29994L2.24195 0.875276L0.592285 2.52494L6.36728 8.29994Z' fill='white'/%3E%3C/svg%3E%0A");
   --icon-facebook: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5Z' fill='black'/%3e %3c/svg%3e");
   --icon-instagram: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M12 2.667c2.536 0 2.852.009 3.847.056.994.046 1.67.202 2.266.434a4.551 4.551 0 0 1 1.654 1.076 4.58 4.58 0 0 1 1.076 1.654c.23.594.388 1.272.434 2.266.044.995.056 1.311.056 3.847s-.009 2.852-.056 3.847c-.046.994-.203 1.67-.434 2.266a4.558 4.558 0 0 1-1.076 1.654 4.588 4.588 0 0 1-1.654 1.076c-.594.23-1.272.388-2.266.434-.995.044-1.311.056-3.847.056s-2.852-.009-3.847-.056c-.994-.046-1.67-.203-2.266-.434a4.564 4.564 0 0 1-1.654-1.076 4.577 4.577 0 0 1-1.076-1.654c-.232-.594-.388-1.272-.434-2.266-.044-.995-.056-1.311-.056-3.847s.009-2.852.056-3.847c.046-.995.202-1.67.434-2.266.233-.624.6-1.188 1.076-1.654a4.57 4.57 0 0 1 1.654-1.076c.595-.232 1.27-.388 2.266-.434.995-.044 1.311-.056 3.847-.056Zm0 4.666a4.667 4.667 0 1 0 0 9.334 4.667 4.667 0 0 0 0-9.334Zm6.067-.233a1.167 1.167 0 1 0-2.334 0 1.167 1.167 0 0 0 2.334 0ZM12 9.2a2.8 2.8 0 1 1 0 5.6 2.8 2.8 0 0 1 0-5.6Z' fill='black'/%3e %3c/svg%3e");
   --icon-twitter: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M22.162 5.656a8.385 8.385 0 0 1-2.402.658A4.195 4.195 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.211 4.211 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.395 8.395 0 0 1-6.19 1.732 11.83 11.83 0 0 0 6.409 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.495 8.495 0 0 0 2.087-2.165v-.001Z' fill='black'/%3e %3c/svg%3e");
   --icon-youtube: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022ZM10 15.5l6-3.5-6-3.5v7Z' fill='black'/%3e %3c/svg%3e");
    --icon-location: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M13.773 13.023L9 17.796L4.227 13.023C3.28301 12.079 2.64014 10.8763 2.3797 9.56689C2.11925 8.25752 2.25293 6.90032 2.76382 5.66693C3.27472 4.43353 4.13988 3.37933 5.24991 2.63764C6.35994 1.89594 7.66498 1.50006 9 1.50006C10.335 1.50006 11.6401 1.89594 12.7501 2.63764C13.8601 3.37933 14.7253 4.43353 15.2362 5.66693C15.7471 6.90032 15.8808 8.25752 15.6203 9.56689C15.3599 10.8763 14.717 12.079 13.773 13.023ZM9 11.25C9.79565 11.25 10.5587 10.9339 11.1213 10.3713C11.6839 9.80871 12 9.04565 12 8.25C12 7.45435 11.6839 6.69129 11.1213 6.12868C10.5587 5.56607 9.79565 5.25 9 5.25C8.20435 5.25 7.44129 5.56607 6.87868 6.12868C6.31607 6.69129 6 7.45435 6 8.25C6 9.04565 6.31607 9.80871 6.87868 10.3713C7.44129 10.9339 8.20435 11.25 9 11.25Z' fill='black'/%3e %3c/svg%3e");

}

.icon {
    --size: 22px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: var(--size);
    height: var(--size);
    display: inline-block;
    transition: .3s all;
}
.icon-facebook{ background-image: var(--icon-facebook) };
.icon-instagram{ background-image: var(--icon-instagram) };
.icon-twitter{ background-image: var(--icon-twitter) };
.icon-youtube{ background-image: var(--icon-youtube) };
.icon-arrow{ background-image: var(--icon-arrow) };
.icon-location{ background-image: var(--icon-location) };
