//MARGINS
.my-88 {
  margin: 5.5rem auto;
}

.mt-75 {
  margin-top: 4.688rem;
}

.mb-100 {
  margin-bottom: 6.25rem;
}

.my-100 {
  // margin: 6.25rem auto;
  margin-top: var(--sp-100);
  margin-bottom: var(--sp-100);
}


//PADDING
.py-88 {
  padding: 5.5rem 0;
}

.pt-88 {
  padding-top: 5.5rem;
}

.pb-88 {
  padding-bottom: 5.5rem;
}

.pt-75 {
  padding-top: 4.625rem;
}

.pt-70 {
  padding-top: 2.625rem;;
}

.pb-75 {
  padding-bottom: 4.625rem;
}

//GENERAL
.text-white {
  color: #FFFFFF;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.under_line {
  background-color: #21BAB3;;
  height: 0.125rem;
  margin: .5rem 0;
  width: 1.5rem;
}

.gap-y-100 {
  row-gap: 6.25rem;
}

.bg-documents {
  background-color: #F3F8FB;
}

.visually-hidden{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.border-gray{
  border: 1px solid #D6EAF6;
}

.text-center{
  text-align: center !important;
}

.text-start{
  text-align: start !important;
}

.text-end{
  text-align: end !important;
}