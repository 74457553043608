.news {
  &_wrap {
    border-bottom: 1px solid #D7E1EA;
  }

  &_header {
    margin-bottom: 2.5rem;

    &_title {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-4);

      text-align: center;
      margin-bottom: 1rem;
      color: #454F5B;
    }

    &_category {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: 18px;

      text-align: center;
      text-transform: uppercase;
      color: var(--c-secondary);
      margin-bottom: 1rem;
    }

    &_date {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: 14px;

      text-align: center;
      color: #454F5B;
      margin-bottom: var(--sp-40)!important;
    }

    &_figure {
      border-bottom: 1px solid #D7E1EA;
    }

    &_image {
      border-radius: 0.5rem;
      object-fit: cover;
      max-height: 30rem;
      width: 100%;
    }

    &_excerpt {
      font-family: var(--ff-primary);
      font-size: 14px;
      color: #454F5B;
      margin: 0.5rem 0;
    }

  }

  &_body {
    width: 60%;
    margin: auto;

    @include m_query($boo-lg) {
        width: 100%;
    }

    &_content {
      font-family: var(--ff-primary);
      font-size: 16px;
      color: #454F5B;
      margin-bottom: 2.5rem;

      img{
        width: 100%;
        height: auto;
      }
    }

  }
}

.event {
  &_wrap {
    border-bottom: 1px solid #D7E1EA;
  }

  &_header {
    margin-bottom: 2.5rem;

    &_title {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-4);

      text-align: center;
      margin-bottom: 1rem;
      color: #454F5B;
    }

    &_category {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: 18px;

      text-align: center;
      text-transform: uppercase;
      color: var(--c-secondary);
      margin-bottom: 1rem;
    }

    &_date {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: 14px;

      text-align: center;
      color: #454F5B;
      margin-bottom: var(--sp-40)!important;
    }

    &_figure {
      background: #005F86;
      border-radius: 8px;
      min-height: 25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @include m_query($boo-sm) {
          min-height: 10rem;
      }
    }

    &_image {
      width: 20rem;
    }

    &_excerpt {
      font-family: var(--ff-primary);


      font-size: 14px;

      color: #454F5B;
      margin: 0.5rem 0;
    }

  }

  &_body {
    display: grid;
    column-gap: 6.25rem;
    grid-template-columns: 1fr 21rem;
    margin-bottom: var(--sp-80);

    @include m_query($boo-lg) {
      column-gap: 2.25rem;
    }

    @include m_query($boo-sm) {
      grid-template-columns: 1fr
    }

    &_content {
      font-family: var(--ff-primary);


      font-size: 16px;

      color: #454F5B;
      margin-bottom: 2.5rem;

      &_subtitle {

        font-weight: 600;
        font-size: var(--fs-2);

        color: #454F5B;
        margin-bottom: 1.5rem;
      }
    }

    &_sidebar {
      background: #E7F3FF;
      border-radius: 8px;
      padding: 2.75rem 1.5rem;

      @include m_query($boo-sm) {
      padding: 1.75rem 1.5rem;
      }

      &_divider {
        height: 1px;
        background-color: #D7E1EA;
        width: 100%;
        margin: 2.5rem 0;

        @include m_query($boo-sm) {
        margin: 1.5rem 0;

        }
      }

      &_title {
        font-family: var(--ff-secondary);

        font-weight: 600;
        font-size: var(--fs-2);

        color: #454F5B;
        margin-bottom: 1.5rem;

        .icon {
          margin-right: 0.5rem;
        }

      }

      &_description {
        font-family: var(--ff-primary);


        font-size: 16px;

        color: #454F5B;
      }
    }

  }
}

.bulletin {
  &_wrap {
    border-bottom: 1px solid #D7E1EA;
  }

  &_header {
    margin-bottom: 4.5rem;

    &_title {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-4);

      text-align: center;
      margin-bottom: 1rem;
      color: #454F5B;
    }

    &_category {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: 18px;

      text-align: center;
      text-transform: uppercase;
      color: var(--c-secondary);

      margin-bottom: 1rem;
    }

    &_date {
      font-family: var(--ff-secondary);
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #454F5B;
      margin-bottom: var(--sp-40)!important;
    }

    &_figure {
      //border-bottom: 1px solid #D7E1EA;
    }

    &_image {
      border-radius: 0.5rem;
      object-fit: cover;
      max-height: 30rem;
      width: 100%;
    }

    &_excerpt {
      font-family: var(--ff-primary);


      font-size: 14px;

      color: #454F5B;
      margin: 0.5rem 0;
    }

  }

  &_body {
    display: grid;
    column-gap: 6.25rem;
    grid-template-columns: 1fr 21rem;
    margin-bottom: var(--sp-80);

    @include m_query($boo-md) {
    column-gap: 2.25rem;
    }

    @include m_query($boo-sm) {
      grid-template-columns: 1fr;
    }

    &_content {
      font-family: var(--ff-primary);


      font-size: 16px;

      color: #454F5B;
      margin-bottom: 2.5rem;

      &_subtitle {
        font-weight: 600;
        font-size: var(--fs-2);
        color: #454F5B;
        margin-bottom: 1.5rem;
      }

      &_text{
        img{
          width: 100%!important;
          height: auto!important;
        }
      }
    }

    &_sidebar {
      background: #E7F3FF;
      border-radius: 8px;
      padding: 2.75rem 1.5rem;

      &_divider {
        height: 1px;
        background-color: #D7E1EA;
        width: 100%;
        margin: 2.5rem 0;
      }

      &_title {
        font-family: var(--ff-secondary);

        font-weight: 600;
        font-size: var(--fs-2);

        color: #454F5B;
        margin-bottom: 1.5rem;

        .icon {
          margin-right: 0.5rem;
        }

      }

      &_description {
        font-family: var(--ff-primary);


        font-size: 16px;

        color: #454F5B;
      }
    }

  }
}

.property {
  &_wrap {

  }

  &_content {
    background-color: #E7F3FF;

    &--content {
      padding: 3rem 4.25rem;

      @include m_query($boo-sm) {
          padding: 2rem;
      }
    }
  }



  &_category {
    font-family: var(--ff-secondary);

    font-weight: 600;
    font-size: 14px;

    text-transform: uppercase;
    color: #454F5B;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &_title {
    font-family: var(--ff-secondary);

    font-weight: 600;
    font-size: var(--fs-3);

    color: #454F5B;
    margin-bottom: 1rem;
  }

  &_description {
    font-family: var(--ff-primary);


    font-size: 14px;

    color: #454F5B;
  }

  &_label {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 14px;
    color: #454F5B;
    text-transform: uppercase;
  }

  &_info {
    font-family: var(--ff-primary);


    font-size: 16px;

    color: #454F5B;

    &--price {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-2);

      color: #005F86;
    }
  }
}

.label_share {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #454F5B;
  text-transform: uppercase;
}