.exchange {
  &_wrap {
    background-position: right;
    background-repeat: repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr 1fr 35rem;
    min-height: 32.25rem;

    @include m_query(1024px) {
      grid-template-columns: 1fr;
    }

  }

  &_rate_info {
    background-color: #f3f8fb;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: var(--sp-64) var(--sp-40);

    &_title {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-4);

      color: var(--c-body-primary);
      margin-bottom: 1rem;
    }

    &_desc {
      font-family: var(--ff-primary);

      font-size: var(--fs-1);

      color: var(--c-body-primary);
    }

    &_button {
      margin-top: auto;
      display: block;
      text-align: center;
    }
  }

  &_rate_cover {
    &_image {
      max-height: 32.25rem;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @include m_query(1024px) {
      display: none;

    }

  }

  &_rate_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--sp-40);

    @include m_query($boo-sm) {
        padding: 1rem;
    }


    &--country {
      @include m_query($boo-sm) {
        width: 100%;
        margin-bottom: 0;

        .mb-4 {
          margin-bottom: 0 !important;
        }
      }
    }

    &--currencies {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;
      text-align: center;

      @include m_query($boo-sm) {
        flex-wrap: wrap;
      }

      .title {
        font-family: var(--ff-secondary);

        font-weight: 600;
        font-size: var(--fs-2);

        color: var(--c-body-primary);
      }

      p {
        font-family: var(--ff-primary);
        font-weight: 900;
        font-size: 16px;
        color: var(--c-body-primary);
      }
    }

    &--inputs {
      &--desc {
        font-family: var(--ff-primary);
        font-size: var(--fs-1);
        color: var(--c-body-primary);
        margin-bottom: 1rem;
      }

      &--wrap {
        border: 1px solid #d7e1ea;
        margin-bottom: 1rem;
        max-height: 48px;
        display: flex;

        h4 {
          font-family: var(--ff-primary);
          font-size: var(--fs-1);
          color: var(--c-body-primary);
        }

        input[type="text"] {
          outline: 0;
          padding: 0.5rem 1rem;
          width: 100%;

          &:focus{
            outline: revert;
          }
        }

        select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00001 5.17205L11.95 0.222046L13.364 1.63605L7.00001 8.00005L0.636013 1.63604L2.05001 0.222045L7.00001 5.17205Z' fill='%23454F5B'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 0.7rem top 50%;
          background-size: 0.65rem auto;
          background-color: #ffffff;
          color: var(--c-body-primary);
          font-family: var(--ff-primary);
          font-size: 16px;
          padding: 0 2.5rem;

          @include m_query($boo-sm) {
            padding-left: 0;
            padding-right: 1.4rem;
          }
        }
      }

      &--result {
        display: flex;
        justify-content: space-between;

        .title {
          font-family: var(--ff-primary);
          font-size: 16px;
          color: var(--c-body-primary);
          font-weight: 700;

          @include m_query($boo-sm) {
            flex: 1;
            max-width: 40%;
          }
        }

        h6 {
          font-family: var(--ff-primary);
          font-size: 16px;

          color: var(--c-body-primary);
          font-weight: 900;
        }
      }
    }
  }
}

.exchange_components {
  .text_image_section {
    width: 100% !important;
    background: #FFF !important;

    & > .container {
      padding: 0 !important;
    }
  }

  .text_image_element {
    width: 100% !important;
  }

  .text_image_content {
    width: 100% !important;
    padding-left: 0 !important;

    & > p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #454F5B;
    }
  }
}

.exchange_title {

  &-content {
    background-color: #FFFFFF;
    z-index: 40;
    border: 1px solid rgba(214, 234, 246, 1);
    height: 100% !important;
    background-position: right;
    background-repeat: repeat;
    background-size: cover;
    min-height: 32.25rem;
  }

  &--title {
    font-size: var(--fs-4);
    color: #454F5B;
  }
}