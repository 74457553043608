.news_card {
  background-color: white;
  transition: background 0.3s, border 0.3s, border-radius 0.3s,
  box-shadow 0.3s;
  border-radius: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #D7E1EA;
  background: white;

  &_image {
    border-radius: 1rem;
    // box-shadow: 32px 32px 64px -24px rgb(0 0 0 / 16%);
    height: 15rem;
    object-fit: cover;
    width: 100%;
    min-height: 55%;
    margin-left: -2px;
    margin-right: -2px;
    margin-top: -2px;
    max-width: none;
    width: calc(100% + 4px);
  }

  &_link {
    border-radius: 1rem;
    overflow: hidden;
    transition: var(--g-trans) box-shadow;
    @include hover {
      box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.12);
    }
  }

  &_caption {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_date {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #454f5b;
    margin-bottom: 1rem;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-1);
    color: #454f5b;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &_cta {
    display: flex;
    font-family: var(--ff-secondary);
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #637381;

    img {
      margin-left: 5px;
    }
  }
}

.event_card {
  // box-shadow: 0 64px 200px 0 rgb(0 0 0 / 8%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s,
  box-shadow 0.3s;
  border: 1px solid #d7e1ea;
  border-radius: 1rem;
  padding: 1.5rem;
  height: 100%;

  &_link {
    border-radius: 1rem;
    overflow: hidden;
    transition: var(--g-trans) box-shadow;

    @include hover {
      box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.12);
    }
  }

  &_caption {
    align-items: center;
    border-bottom: 1px solid #d7e1ea;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  &_date {
    align-items: center;
    column-gap: 0.5rem;
    color: #454f5b;
    display: flex;
    font-family: var(--ff-secondary);

    font-weight: 600;
    font-size: 14px;

    text-transform: uppercase;
    margin: 0;
  }

  &_type {
    color: #454f5b;
    font-size: 14px;
    text-transform: uppercase;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-1);
    color: #454f5b;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.bulletin_card {
  // box-shadow: 0 64px 200px 0 rgb(0 0 0 / 8%);
  background: #1b5173;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 25rem;
  border-radius: 1rem;
  overflow: hidden;
  transition: var(--g-trans) box-shadow;

  @include hover {
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.12);
  }

  // &_image {
  //   margin-bottom: 2.5rem;
  // }

  &_type {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0rem;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-2);
    text-align: center;
    color: #ffffff;
    margin-bottom: 0rem;
  }

  &_date {
    font-family: var(--ff-secondary);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
  }
}

.report-card {
  border: 1px solid #d7e1ea;
  border-radius: 8px;
  padding: 0.5rem 0.5rem 1rem 0.5rem;

  @include m_query($boo-sm) {
    margin: 0 auto;
  }

  &_img {
    border-radius: 4px;
    margin-bottom: 1rem;
    width: 100%;
    max-width: none;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #454f5b;
  }

  &:hover {
    border: 1px solid var(--c-secondary);

    .report-card_title {
      color: var(--c-secondary);
    }
  }
}

.value_card {
  background-color: #f3f8fb;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 20rem;
  padding: 5rem 2.5rem;

  @include m_query($boo-sm) {
    padding: 3rem 1.5rem;
    min-height: auto;
  }

  &_caption {
    width: 60%;

    @include m_query($boo-sm) {
      width: 90%;
    }
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-4);
    color: #454f5b;
  }

  &_description {
    font-family: var(--ff-primary);
    font-size: 16px;
    color: #454f5b;
  }
}

.blur_card {
  align-items: center;
  background: rgba(255, 255, 255, 0.16);
  box-shadow: 0 0 24px -2px rgba(69, 79, 91, 0.08);
  backdrop-filter: blur(16px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 20rem;
  padding: 4rem;

  @include m_query($boo-sm) {
    min-height: auto;
    padding: 2rem;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-4);
    text-align: center;
    color: #ffffff;
    margin-bottom: 1rem;
  }

  &_description {
    font-family: var(--ff-primary);
    font-size: var(--fs-1);
    text-align: center;
    color: #ffffff;
  }
}

.trust_card {
  &_caption {
    border-left: 0.25rem SOLID #005f86;
    padding-left: 0.5rem;

    &_title {
      font-family: var(--ff-secondary);
      font-weight: 600;
      font-size: var(--fs-2);
      color: #454f5b;
      margin-bottom: 1rem;
    }
  }

  &_position {
    font-family: var(--ff-primary);
    font-size: 16px;
    color: #005f86;
    padding-left: 0.75rem;
  }
}

.financial_card {
  padding: 2.5rem 1.5rem;
  height: auto;

  @include m_query($boo-sm) {
    padding: 1rem 0;
  }

  &_img {
    margin-bottom: 1.875rem;
    max-width: 4.75rem;

    @include m_query($boo-sm) {
      margin-bottom: 1rem;
      max-width: 2.5rem;
    }
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-2);
    color: #454f5b;
    margin-bottom: 1rem;
  }

  &_description {
    font-family: var(--ff-primary);
    font-size: 16px;
    color: #24272a;
    margin-bottom: 3.125rem;

    @include m_query($boo-sm) {
      margin-bottom: 1.125rem;
    }
  }

  &_link {
    display: flex;
    column-gap: 0.75rem;
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #454f5b;
  }

  &_button {
    display: flex;
    vertical-align: middle;
    text-align: start;
    align-items: center;
    background: #caf6f3;
    border-radius: 8px;
    padding: .5rem;

    & > h2 {
      font-family: var(--ff-primary);
      font-weight: 900;
      font-size: 16px;
      color: #00a499;
    }

    & > p {
      font-family: var(--ff-primary);
      font-size: 14px;
      color: #00a499;
    }
  }

  &:hover {
    box-shadow: 0 64px 200px 0 rgb(0 0 0 / 8%);
  }
}

.contact_card {
  background-color: #f3f8fb;
  padding: 1.25rem 2.188rem;

  &__header {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e8e8e8;
  }

  &__body {
    margin-top: 1.5rem;
  }

  &__title {
    font-family: var(--ff-primary);
    font-weight: 900;
    font-size: 16px;
    color: #00a499;
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {
    margin-bottom: 1rem;

    &:last-child {
      margin: 0;
    }
  }

  &__link {
    font-family: var(--ff-primary);
    font-size: 14px;
    color: #454f5b;
  }
}

.property_card {
  background-color: #ffffff;
  border: 1px solid #d7e1ea;
  border-radius: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &_image {
    border-radius: 16px 16px 16px 16px;
    box-shadow: 32px 32px 64px -24px rgb(0 0 0 / 16%);
    height: 15rem;
    object-fit: cover;
    width: 100%;
    min-height: 15rem;
  }

  &_caption {
    padding: 1rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include m_query($boo-sm) {
      padding: 1rem 1.5rem 1.5rem;
    }

    &_top {
      border-bottom: 1px solid #d7e1ea;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    &_bottom {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  &_category {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #454f5b;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &_price {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-2);
    color: #005f86;
    margin-bottom: 0;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-1);
    color: #454f5b;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &_content {
    font-family: var(--ff-primary);
    font-size: 14px;
    color: #454f5b;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2rem;

    @include m_query($boo-sm) {
      margin-bottom: 1rem;
    }
  }

  &_cta {
    display: flex;
    font-family: var(--ff-secondary);
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #637381;

    img {
      margin-left: 5px;
    }
  }

  &:hover {
    box-shadow: 0 64px 200px 0 rgb(0 0 0 / 8%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
  }
}

.highlighted_card {
  align-items: center;
  display: flex;
  gap: 2rem;
  height: 100%;
  padding: 2.5rem;

  @include m_query($boo-sm) {
    padding: 1.5rem;
    gap: 1rem;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-3);
    color: #ffffff;
    order: 1;

    @include m_query($boo-sm) {
      font-size: 1rem;
    }
  }

  img {
    @include m_query($boo-sm) {
      --size: 55px;
      width: var(--size);
      height: var(--size);
    }
  }
}

.slider-reports-cards {
  .slider_tracker--right {
    @include m_query($boo-lg) {
      right: -3rem !important;
    }
  }

  .slider_tracker--left {
    @include m_query($boo-lg) {
      left: 0 !important;
      z-index: 10 !important;
    }

    @include m_query($boo-sm) {
      left: 0.4rem !important;
    }
  }
}

.customer_services_card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  background: var(--c-gray-light);
  padding: 2rem;
  border-radius: 10px;
  background: #F0F4F8;
  max-width: none;
  width: 100%;
  position: relative;
  -webkit-transition: var(--g-trans) background, var(--g-trans) box-shadow;
  transition: var(--g-trans) background, var(--g-trans) box-shadow;

  &:hover {
    -webkit-box-shadow: inset 0 0 0 3px #c9d1d7;
    box-shadow: inset 0 0 0 3px #c9d1d7;
    background: #dee6ed;
  }

  @include m_query($boo-sm) {
    padding: 1rem;

    img {
      --size: 35px;
      object-fit: contain;
      width: var(--size);
      height: var(--size);
    }
  }

  &_icon {
    height: 4.5rem;
    margin-right: 2rem;
    object-fit: contain;
  }

  &_title {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-1);
    color: #454f5b;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;
  }
}