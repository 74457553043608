.mc-icon {
    padding: var(--sp-100) 0;

    .row-content {
        margin-top: 3rem;

        .col {
            @include m_query($boo-xl) {
                @include col(4);
            }

            @include m_query($boo-md) {
                @include col(6);
            }

            @include m_query($boo-sm) {
                @include col(12);
            }
        }
    }

    &__item {
        max-width: 280px;
        text-align: center;
        margin: 0 auto;
    }

    &__icon {
        margin: 0 auto;
        margin-bottom: 1.5rem;
        width: 90px;
        height: 58px;
        object-fit: contain;
    }

    &__title {
        // font-size: var(--fs-1);
        font-size: 15px !important;
        font-family: var(--ff-secondary);
        font-weight: 400 !important;
        margin-bottom: 0.5rem;
    }

    &__description {
        padding: 0 1rem;
    }

    &--use-sidebar {
        padding: 0;

        .row-content {
            margin-top: 0 !important;

            .col {
                @include col(3);

                @include m_query($boo-xl) {
                    @include col(4);
                }

                @include m_query($boo-sm) {
                    @include col(12);

                }
            }
        }

        .mc-icon {

            &__title {
                font-size: 17px;
            }
        }
    }
}

.sec-title {
    text-align: center;

    &__title {
        font-size: var(--fs-4);
        margin-bottom: 0.5rem;
    }

    &__subtitle {}
}