//Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

.grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

//Animate css
@import '~animate.css/animate.css';

//Glide
// @import "~@glidejs/glide/src/assets/sass/glide.core";

//tinySlider
@import "tiny-slider";