.box-list {
  &__title {
    margin-bottom: 2rem;
    font-size: var(--fs-3);
  }

  &__item {
    ol {
      list-style: revert;
      padding: 0;
      list-style-position: inside;
      // grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      &[data-type="abc"]{
        list-style-type: upper-latin;
      }

      @include m_query(81.25rem) {
        // grid-template-columns: repeat(2, 1fr);
      }

      @include m_query($boo-sm) {
        grid-template-columns: 1fr;
        gap: 1rem;

      }
    }

    li {
      background: #F3F8FB;
      padding: 2rem;

      &::marker {
        font-size: var(--fs-2);
        font-family: var(--ff-secondary);
        color: var(--c-secondary);
        font-weight: 700;
      }
    }
  }

  &__data {
    padding-left: 2.5rem;
    margin-top: -1.6rem;
  }

  &--icons {
    @media only screen and (max-width: 640px) {
      & {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
      }
    }
  }
}