.video {
	&-container {
		width: 100%;
		min-height: 35rem;
		display: flex;
		flex-direction: row;
		background-color: #005f86;
		color: white;
		position: relative;

		@media screen and (max-width: 600px) {
			flex-direction: column;
		}

	}

	&-card {
		transition: all 800ms ease-in;
		width: 50%;
		position: relative;
		max-width: 40rem;
		margin-left: auto;
		z-index: 2;

		@include m_query($boo-sm) {
			width: 100% !important;
			padding: var(--sp-40) 0.5rem;
			order: 1;
		}

		&-played {
			width: 40%;
			transition: all 800ms ease-in;

			@include m_query($boo-sm) {
				width: 100%;
				padding: 2rem;
				order: 2;
				padding: var(--sp-40) 0.5rem;
			}
		}
	}

	&-btn-play-wrapper {
		--size: 150px;
		width: var(--size);
		height: var(--size);
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%) translateX(50%);
		background: var(--c-primary);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		border-radius: 50%;
		transition: var(--g-trans) opacity, var(--g-trans) visibility;

		@include m_query($boo-sm) {
			top: 0;
			--size: 60px;
			right: 3rem;
		}
	}

	&-btn-play {
		background: var(--c-secondary);
		border-radius: 50%;
		z-index: 1;
		--size: 90px;
		width: var(--size);
		height: var(--size);
		display: flex;
		justify-content: center;
		align-items: center;
		transition: var(--g-trans) background;

		@include hover {
			background: var(--c-secondary-dark);
		}

		@include m_query($boo-sm) {
		--size: 40px;

		}

		img {
			width: 15px;
			transform: translateX(2px);
		}
	}

	&-btn-play-label {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%) translateX(calc(100% + 2rem));
		font-size: 1.2rem;
		width: max-content;
		pointer-events: none;
		user-select: none;
		text-shadow: 0 0 8px black;

		@include m_query($boo-sm) {
			@include visually-hidden;
		}
	}
}

.card-content {
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
	align-items: center;

	& .card-content-text {
		width: 80%;

		@include m_query($boo-sm) {
			width: 90%;
		}
	}
}

.card-content-text>h2 {
	font-family: var(--ff-secondary);

	font-weight: 600;
	font-size: var(--fs-4);
}

.card-content-text>p {
	margin-top: 16px;
	font-family: var(--ff-primary);
	font-size: var(--fs-1);
}

.card-content-text>button {
	margin-top: 2rem;
	width: 220px;
	height: 56px;
	background-color: var(--c-secondary);
	border-radius: 4px;
}

.card-content-played {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	& .card-content-text {
		width: 80%;

		@media screen and (max-width: 600px) {
			width: 100;
			margin-bottom: 1.5rem;
		}
	}
}

.card-content-played>h2 {
	// width: 300px;
	font-family: var(--ff-secondary);

	font-weight: 600;
	font-size: var(--fs-4);
}

.card-content-played>p {
	margin-top: 16px;
	font-family: var(--ff-primary);

	font-size: var(--fs-1);
}

.card-content-played>button {
	margin-top: 10px;
	width: 220px;
	height: 56px;
	background-color: var(--c-secondary);
	border-radius: 4px;
}

.play {
	--size: 150px;
	z-index: 1;
	position: absolute;
	top: 38%;
	right: -4.688rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	background-color: #005f86;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	transition: all 800ms ease-in;

	@include m_query($boo-sm) {
		top: -20%;
		right: 0;
		--size: 6.25rem;
	}

	>button {
		--size: 80px;
		width: var(--size);
		height: var(--size);
		border-radius: 50%;
		background-color: var(--c-secondary);
		display: flex;
		justify-content: center;
		align-items: center;

		@include m_query($boo-sm) {
			--size: 44px;
		}

		img {
			position: relative;
			left: 0.1em;
		}
	}
}

.play-video-text {
	position: absolute;
	right: -15rem;
	top: 50%;
	font-family: var(--ff-primary);

	font-size: var(--fs-1);

	z-index: 1;
}

.play-played {
	z-index: 1;
	position: absolute;
	bottom: 0px;
	left: 44%;
	opacity: 1;
	transition: all 800ms ease-in;

	@media screen and (max-width: 600px) {
		left: 6%;
	}
}

.play-played>button {
	font-size: 26px;
}

.video-player {
	position: relative;
	width: 50%;
	z-index: 1;
	transition: var(--g-trans) opacity, var(--g-trans) visibility, var(--g-trans) width;

	@include m_query($boo-sm) {
		width: 100%;
	}

	& .ImageVideo {
		width: 100%;
		position: absolute;
		object-fit: cover;
		height: 100%;
		opacity: 1;
		transition: all 600ms ease-in;
		z-index: 1;
	}
}

.video {
	height: 100%;
	width: 100%;
}

.video-player-played {
	position: relative;
	width: 60%;
	transition: all 800ms ease-in;

	& .ImageVideo {
		position: absolute;
		width: 100%;
		object-fit: cover;
		height: 100%;
		opacity: 0;
		transition: all 600ms ease-in;
	}

	@media screen and (max-width: 600px) {
		& {
			width: 100%;
			height: 300px;
			order: 1;
		}
	}
}

.video-played {
	height: 100%;
	width: 100%;
	position: absolute;
	background-size: cover;
	top: 0;
	left: 0;
	display: block;
	object-fit: cover;
}

.progress {
	width: 50%;
	flex: 10;
	display: flex;
	flex-basis: 100%;
	height: 2px;
	background-color: #bfbfbf;
	cursor: pointer;
	height: 5px;
	position: absolute;
	bottom: 2%;
	right: 3%;

	@media screen and (max-width: 600px) {
		width: 80%;
	}
}

.progress-filled {
	width: 50%;
	background: var(--c-secondary);
	flex: 0;
	flex-basis: 0%;
}

/*————————————————————————————————————————————————————*\
	●❱ VIDEO STATUS: Played
\*————————————————————————————————————————————————————*/

.video-container {
	&.played {

		.video-btn-play-wrapper,
		.ImageVideo {
			opacity: 0;
			visibility: hidden;
		}

		.video-player {
			width: 60%;

			@include m_query($boo-sm) {
				width: 100%;
			}
		}
	}
}