.banner_section {
  //background: linear-gradient(180deg, #01538F 0%, #005F86 100%);
  background-color: #01538f;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: normal;
  min-height: 18.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.25rem 0;

  @include m_query($boo-lg) {
      min-height: 8.75rem;
  }
}

.page_title {
  color: #ffffff;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: var(--fs-6);
  // margin-bottom: 1rem;
}

.page_subtitle {
  color: #ffffff;
  font-family: var(--ff-primary);
  font-size: var(--fs-1);
  font-weight: 700;
}

.page_description {
  font-family: var(--ff-primary);
  font-size: 16px;
  margin-bottom: 2rem;
}

.section {
  &__title {
    font-family: var(--ff-secondary);

    font-weight: 600;
    font-size: var(--fs-4);

    text-align: center;
    color: #454f5b;
    margin-bottom: 2.5rem;
  }

  &__title--slider {
    font-family: var(--ff-secondary);

    font-weight: 600;
    font-size: var(--fs-4);

    text-align: center;
    color: #454f5b;
    margin-bottom: 1rem;
  }
}

.text {
  &_element--title {
    font-family: var(--ff-secondary);

    font-weight: 600;
    font-size: var(--fs-4);
  }

  &_element--description {
    font-family: var(--ff-primary);

    font-size: var(--fs-1);
  }

  &_image_section {
    background-color: #f0f4f8;

    &>div>div>h2 {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-4);

      color: #454f5b;
    }

    // &>div>div>ul>li {
    //   color: var(--c-secondary);
    //   font-family: var(--ff-secondary);

    //   font-weight: 500;
    //   font-size: 16px;

    //   padding: 0.5rem 0;
    // }
  }

  &_image_content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-family: var(--ff-secondary);

      font-weight: 600;
      color: #454f5b;
      margin-bottom: 1rem;
      font-size: var(--fs-4);
    }

    ul {
      list-style: none;
      padding: 2rem;

      li {
        padding-left: 0;
        padding-bottom: 0.5rem;
        font-family: var(--ff-secondary);
        font-weight: 500;
        font-size: 16px;
        position: relative;
        color: #454f5b;

        &:before {
          content: "✓";
          position: absolute;
          left: -1.4rem;
        }
      }
    }
  }
}

.list_class {
  color: var(--c-secondary) !important;
}

.backFill {

  a {
    @include hover {
      .backFill {
        &__container--photos {
          .back {
            opacity: 1;
          }
        }

        &__text--tittle {
          color: var(--c-secondary);
        }
      }
    }
  }

  &__photo {
    width: 100%;
    height: 240px;
    background: #d7e1ea;

    @include hover {
      background: #005f86;

    }
  }

  &__container--photos {
    position: relative;
    // height: 290px;
    width: 100%;
  }

  &__container--photos {
    margin-bottom: 1.5rem;

    img {
      transition: opacity 0.5s ease-in-out;
      margin: 0 auto;
    }

    .back {
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__text--tittle {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: var(--fs-3);
    text-align: center;
    color: #454f5b;
    transition: var(--g-trans) color;

    +img {
      position: relative;
      top: 2px;
    }
  }

  &__text--description {
    font-family: var(--ff-primary);
    font-size: 16px;
    text-align: center;
    color: #454f5b !important;
    max-width: 230px;
    margin: 0 auto;
    margin-top: .5rem;

    @include hover {
      color: #005f86 !important;

    }
  }
}

.card {
  position: relative;
  padding: 4rem 2rem;
  display: flex;
  align-items: flex-end;
  height: 100%;
  overflow: hidden;
  min-height: 20rem;
  &__bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bg-gradient{
    position: absolute;
    inset: 0;
    background: rgba(21, 81, 115, 0.8);
    transform: translateY(100%);
    transition: var(--g-trans) transform;
  }

  &__content{
    position: relative;
  }

  &__link{


    @include hover{
      .card__bg-gradient{
        transform: translateY(0);
      }
    }
  }

  // @include hover{
  //   figcaption{
  //     transform: translateY(0%);
  //     background-color: rgba(21, 81, 115, 0.8);
  //     transition: all 1s ease-in;
  //   }
  //   .viewed{
  //     transform: translateY(-30px);
  //   }
  //   .viewed .noViewed {
  //     opacity: 1;
  //     transition: opacity 1s;
  //   }
  // }

  // figcaption{
  //   transform: translateY(100%);
  //   transition: var(--g-trans) transform, var(--g-trans) background;
  // }

  // .viewed{
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-end;
  //   padding: var(--sp-40);
  //   transition: var(--g-trans) transform, var(--g-trans) background;

  //   img{
  //     margin-right: auto;
  //   }

  //   & .noViewed {
  //     list-style: circle;
  //     transition: opacity 1s;
  //     opacity: 0;
  //   }
  // }
}

// .card>img,
// .card>figcaption,
// .card>.viewed {
//   grid-area: stack;
// }

// .card>img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//     object-position: right;
// }

// .card>figcaption {
//   grid-area: stack;
//   transform: translateY(100%);
//   transition: all 1s ease-in;
// }

.green_section {
  background: linear-gradient(180deg, var(--c-secondary) 0%, #005f86 100%);
  mix-blend-mode: normal;
}

.banner_full_width {
  margin-top: 0rem !important;
  
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.work_with_us_section {
  background-color: #f3f8fb;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 22.375rem;
  position: relative;

  &_title {
    font-family: var(--ff-secondary);

    font-weight: 600;
    font-size: var(--fs-4);

    color: #454f5b;
    margin-bottom: 1rem;
  }

  &_description {
    font-family: var(--ff-secondary);

    font-size: 16px;

    color: #454f5b;
    margin-bottom: 1.5rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-2);

      color: #454f5b;
      margin-bottom: 1rem;
    }

    ul,
    ol {
      list-style: none;
      padding: 0;

      li {
        font-family: var(--ff-secondary);
        font-weight: 500;
        font-size: 16px;
        margin-left: 8px;

        &:before {
          content: "✓";
          margin-right: 8px;
        }
      }
    }
  }

  &_cover {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
}

//Frequently Questions Page
.frequenly {
  &__section {
    &--title {
      font-family: var(--ff-secondary);

      font-weight: 600;
      font-size: var(--fs-4);

      color: #454f5b;
    }

    &--containerSearch {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;

      &--search {
        border-bottom: 1px solid rgba(69, 79, 91, 1);
        width: 70%;
        padding-bottom: 10px;

        &::placeholder {
          color: #454f5b !important;
        }

        &:-ms-input-placeholder {
          color: #454f5b !important;
        }

        /* IE 10+ */

        &::-webkit-input-placeholder {
          color: #454f5b !important;
        }

        /* WebKit */

        &::-moz-placeholder {
          color: #454f5b !important;
        }

        /* Firefox 19+ */
      }
    }
  }
}

.accordion {
  padding: 2em;

  @include m_query($boo-sm) {
      padding: 1rem;
  }

  &__block {
    margin: 0 0 1rem;
    padding: 1rem;
    border-top: 1px solid rgba(215, 225, 234, 1);

    &:last-child {
      border-bottom: 1px solid rgba(215, 225, 234, 1);
    }

    &--question {
      text-align: left;
      font-family: var(--ff-primary);
      font-size: var(--fs-1);
      color: #454f5b;
      display: flex;
      justify-content: space-between;
      width: 100%;

      img{
        transition: var(--g-trans) transform;
      }

      &.active{
        .icons{
          transform: rotate(45deg);
        }
      }
    }

    &--answer {
      & > p {
        text-align: left;
        font-family: var(--ff-primary);
        font-size: 16px;
        color: #454f5b;
      }
    }

    & .content {
      padding-top: 0.5rem;
      display: none;
    }
  }

  &__block.activo {
    & .content {
      height: fit-content;
      padding-top: 1rem;
      padding-bottom: 1rem;
      transition: all 1s ease;
    }
  }
}

.two-col-number {
  .container {
    padding: 0;
    margin: 0 !important;
  }
}

.sectionTextBlock {
    background: white;
    padding: 31px 41px;
    border-radius: 10px;
    box-shadow: 0px 16px 40px rgb(112 144 176 / 20%);
    text-align: justify;
}

.title_error {
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 500;
  font-size: var(--fs-3);
  line-height: 38px;
  color: #000;
}

.subtitle_error {
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: var(--fs-2);
  line-height: 28px;
  color: #000;
}

label[for='id_captcha'] {
  display: none;
}