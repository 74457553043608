/*--------------------------------------------------------------
# Vendors
--------------------------------------------------------------*/
@import "vendors/vendors";

/*--------------------------------------------------------------
# Utils
--------------------------------------------------------------*/
@import "bfp_web/static/sass/utils/interpolete";
@import "bfp_web/static/sass/utils/helpers";
@import "bfp_web/static/sass/utils/mixins";

/*--------------------------------------------------------------
# Core
--------------------------------------------------------------*/
@import "bfp_web/static/sass/core/variables";
@import "bfp_web/static/sass/core/reset";
@import "bfp_web/static/sass/core/typography";
@import "bfp_web/static/sass/core/base";


/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
@import "bfp_web/static/sass/layout/grid";
@import "bfp_web/static/sass/layout/header";
@import "bfp_web/static/sass/layout/footer";
@import "bfp_web/static/sass/layout/sidebar";
@import "bfp_web/static/sass/layout/single";


/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import "bfp_web/static/sass/components/buttons";
@import "bfp_web/static/sass/components/breadcrumbs";
@import "bfp_web/static/sass/components/cards";
@import "bfp_web/static/sass/components/forms";
@import "bfp_web/static/sass/components/modals";
@import "bfp_web/static/sass/components/navs";
@import "bfp_web/static/sass/components/paginations";
@import "bfp_web/static/sass/components/sliders";
@import "bfp_web/static/sass/components/tables";
@import "bfp_web/static/sass/components/lists";
@import "bfp_web/static/sass/components/tabs";
@import "bfp_web/static/sass/components/calculator";
@import "bfp_web/static/sass/components/exchange";
@import "bfp_web/static/sass/components/video";
@import "bfp_web/static/sass/components/counter";
@import "bfp_web/static/sass/components/mc-icons";
@import "bfp_web/static/sass/components/map";
@import "bfp_web/static/sass/components/mc-icon-boxes.scss";
@import "bfp_web/static/sass/components/hero-page.scss";
@import "bfp_web/static/sass/components/box-list.scss";
@import "bfp_web/static/sass/components/ta_graphics.scss";










/*--------------------------------------------------------------
# Pages
--------------------------------------------------------------*/
@import "bfp_web/static/sass/pages/single";
@import "bfp_web/static/sass/pages/single_detail";
@import "bfp_web/static/sass/pages/home";