.main{
  //min-height: 92rem;
}

.wrap-articles{
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-12;

}

.related_product{
  background-color: #F0F4F8;
  padding: var(--sp-64) 0;
  position: relative;
}

.content__middle{
  @include m_query($boo-sm) {
      overflow: hidden;
  }
}

.contact_form_wrap{
  display: flex;
  flex-wrap: wrap;
}

.sectionLink{
  .bg-primary-1{
    padding-top: var(--sp-100);
    color: white;
  }
}

[id]{
  scroll-margin-top: 10rem;

  @include m_query($boo-sm) {
  scroll-margin-top: 2rem;

  }
}